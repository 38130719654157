import React, { Fragment } from 'react';
import {
  TextField,
  MenuList,
  MenuItem,
  ClickAwayListener,
  Paper,
  Popper
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
//import clsx from 'clsx';
import FormContext from "../core/formContext";
const styles = (theme) => ({
  suggestionsContainer: {
    direction: 'ltr',
    zIndex: '1000',
  }
})
class AutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentText: '',
      suggetions: [],
      highlightedIndex: null,
      selectedValue: null,
      open: false
    }
    this.anchorRef = React.createRef();
  }
  onValueUpdateHandler = (event) => {
    const currentText = event.target.value;
    const { onChange, dataSource: { allSuggestions, textFieldName } } = this.props;
    const suggetions = currentText ? allSuggestions.filter(item => {
      return item[textFieldName].toLowerCase().indexOf(currentText.toLowerCase()) !== -1;
    }) : null;
    this.setState({
      suggetions,
      highlightedIndex: 0,
      selectedValue: null,
      currentText,
      open: currentText && suggetions.length > 0 ? true : false
    })
    // this.props.formConnect.onTouched();
    //this.props.formConnect.onSelect(null);
    onChange(null);
  }
  onClickAwayHandler = () => {
    this.setState({
      currentText: '',
      suggetions: [],
      highlightedIndex: null,
      selectedValue: null,
      open: false
    })
  }
  onItemClickHandler = (selectedIndex) => {
    const { suggetions } = this.state;
    const { onChange, callValidationEvent, dataSource: { textFieldName } } = this.props;
    this.setState({
      suggetions: [],
      highlightedIndex: 0,
      currentText: suggetions[selectedIndex][textFieldName],
      selectedValue: suggetions[selectedIndex],
      open: false
    })
    //this.props.formConnect.onSelect(suggetions[selectedIndex]);
    onChange(suggetions[selectedIndex]);
    callValidationEvent(suggetions[selectedIndex]);
  }
  onkeydownHandler = (event) => {
    // 13 - enter
    // 38 - up arrow
    // 40 - down arrow
    const { keyCode } = event;
    const { suggetions, highlightedIndex, currentText } = this.state;
    const { dataSource: { textFieldName }, onChange, callValidationEvent } = this.props;
    if (keyCode === 40 && currentText && suggetions.length > highlightedIndex + 1) {
      this.setState({ highlightedIndex: highlightedIndex + 1 })
    }
    else if (keyCode === 38 && currentText && suggetions.length > 0 && highlightedIndex - 1 >= 0) {
      this.setState({ highlightedIndex: highlightedIndex - 1 })
    }
    else if (keyCode === 13 && currentText && suggetions[highlightedIndex]) {
      this.setState({
        suggetions: [],
        highlightedIndex: 0,
        currentText: suggetions[highlightedIndex][textFieldName],
        selectedValue: suggetions[highlightedIndex],
        open: false
      })
      //this.props.formConnect.onSelect(suggetions[highlightedIndex]);
      onChange(suggetions[highlightedIndex])
      callValidationEvent(suggetions[highlightedIndex]);
      event.preventDefault();
    }
  }
  componentDidMount() {
    // load initial data
    const { value, dataSource: { textFieldName } } = this.props;
    if (value) {
      // load initial data
      this.setState(newState => ({
        currentText: value[textFieldName],
        selectedValue: value
      }))
    }
  }
  componentDidUpdate(prevProps) {
    const { state } = this.context;
    if (this.props.disabled && this.state.currentText) {
      this.setState(newState => ({
        currentText: '',
        selectedValue: null
      }))
    }
  }
  handleBlur(textValue) {
    const { selectedValue } = this.state;
    const { callValidationEvent } = this.props;
    if (!selectedValue) {
      //  this.onClickAwayHandler()
      // callValidationEvent("");
    }
  }
  render() {
    const { suggetions, highlightedIndex, currentText, open } = this.state;
    const { classes, value, onChange, callValidationEvent, validating, validationText, dataSource: { textFieldName }, ...otherProps } = this.props;
    const suggestionsPaper = open ? (
      <Popper
        open={true}
        placement={"bottom"}
        anchorEl={this.anchorRef.current}
        className={classes.suggestionsContainer}
      >
        <Paper style={{ width: this.anchorRef.current && this.anchorRef.current.offsetWidth }}>
          <ClickAwayListener onClickAway={this.onClickAwayHandler}>
            <MenuList>
              {suggetions.slice(0, 10).map((item, index) => (
                <MenuItem
                  selected={index === highlightedIndex}
                  onClick={() => this.onItemClickHandler(index)}
                  key={index}
                >
                  {item[textFieldName]}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    ) : null;
    return (
      <Fragment>
        <TextField
          value={currentText}
          onChange={this.onValueUpdateHandler}
          onKeyDown={this.onkeydownHandler}
          ref={this.anchorRef}
          margin="normal"
          helperText={validationText}
          InputProps={{ onBlur: e => this.handleBlur(e.target.value) }}
          {...otherProps}
        />
        {suggestionsPaper}
      </Fragment>
    )
  }
}
AutoComplete.contextType = FormContext;
export default React.memo(withStyles(styles)(AutoComplete));
