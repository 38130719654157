import React, { useEffect, useState,useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { ContactSupportOutlined } from '@material-ui/icons';
import LoginHeader from './Header/LoginHeader';
import ModSnakeBar from './ModSnakeBar/ModSnakeBar';
import { CircularProgress } from "@material-ui/core";
import { AppContext } from '../../AppContext';



const MainLayout = ({children})=> {
    //const [snackBarProps, setSnackBarProps] = useState({ display: false, variant: 'info', message: '', vertical: '', horizontal: '' });
    //const [loading, setLoading] = useState(false);
    const { Dispatcher,snackBarProps,loading } = useContext(AppContext);
    const gotomain=()=>{
        //props.history.push('/login');
    }
    return (
        <>
            <LoginHeader />
            <div className='loginwrapper'>
                <div className="ModLogin">
                    <h1>כניסה לאיזור אישי</h1>
                    <h2>{Dispatcher && Dispatcher.siteName}</h2>                   
                    {children}
                    {/* <button  type="link" name="mainsitelink" className="mainsitelink" onClick={}>בחזרה לדף ראשי</button> */}
                    {/* <Link className='mainsitelink' to='/login'>בחזרה לדף ראשי</Link> */}
                   { Dispatcher && <a className='mainsitelink' href={Dispatcher.PersonalAreaUrl}>בחזרה לדף ראשי</a>}
                    {snackBarProps &&
                        <ModSnakeBar {...snackBarProps} />
                    }
                    {loading ? <CircularProgress className="progressBar" /> : null}
                </div>
            </div>
        </>
    );
}
export default MainLayout;