import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import useForm from '../../formsFramework/core/useForm';

import api from '../../webApi';
import clsx from "clsx";
import moment from 'moment';
import './Login.css';
import loginbackground from '../../assets/images/loginbackground.png';
import usersignin from '../../assets/images/usersignin.png';
import usersms from '../../assets/images/usersms.png';
import { require, onlyNumbers, email, minLength, maxLength, length } from "../../formsFramework/validators";
import phonePrefixes from '../../assets/static-data/phonePrefixes';
import { FormContainer, FormField } from '../../formsFramework';
import { FormTextField } from "../../formsFramework/MaterialComponents";
import Divider from '@material-ui/core/Divider';
import { async } from 'react-app-polyfill/node_modules/regenerator-runtime';
//import ModSnakeBar from '../layout/ModSnakeBar/ModSnakeBar';
import ModModal from '../../components/layout/ModModal/ModModal';
import { CircularProgress } from "@material-ui/core";
import { OutlinedInput, InputAdornment, FormControl, InputLabel, IconButton } from "@material-ui/core";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { AppContext } from '../../AppContext';
// import LoginHeader from '../layout/Header/LoginHeader';

const getIsMobile = () => window.innerWidth <= 800;
const ForgotPassword = React.memo(props => {
    const [isMobile, setIsMobile] = useState(getIsMobile());
    const [isSMSview, setIsSMSview] = useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    //const [snackBarProps, setSnackBarProps] = useState({ display: false, variant: 'info', message: '', vertical: '', horizontal: '' });
    const [displayModal, setDisplayModal] = useState(false);
    //const [disableButReg,setDisableButReg] = useState(true);
    //const [loading, setLoading] = useState(false);
    const { Dispatcher,displaySnackbarHandler,setLoading } = useContext(AppContext);
    const history = useHistory();

    let btnRef = useRef();

    const sleep = async (time) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve()
            }, time)
        })
    }
    useEffect(() => {


        const onResize = () => {
            setIsMobile(getIsMobile);
        };
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);
    const displaySnackbar = (variant, message = '', vertical = '', horizontal = '') => {
        //setSnackBarProps({ display: true, variant: variant, message: message, vertical: vertical, horizontal: horizontal });
        displaySnackbarHandler(variant, message , vertical , horizontal ) ;
        setLoading(false);
    }
    const handleOnSubmit = async (validatioState, form) => {
        try {
            btnRef.current.focus();
            btnRef.current.disabled = true;

            if (validatioState) {
                setLoading(true);
                const loginDTO = {
                    UserKey: form.userKey,
                    Email: form.MODEmail,
                    Userid: form.MODID,
                    siteurl: Dispatcher.rootUrl
                }
                const res = await api.post(`ModLogin/ForgotPassword`, loginDTO).then(response => {
                    if (response) {
                        if (response && response.data && !response.data.status) {
                            displaySnackbar("error", "משתמש לא קיים במערכת, אנא פני/ה למוקד התמיכה", 'top', 'center');

                        }
                        else {
                            let q = response.data.Data;
                            history.push({
                                pathname: "/QandAnswer",
                                state: { loginDTO: loginDTO, question: q }
                            });
                        }
                    }
                    else {
                        displaySnackbar("error", "משתמש לא קיים במערכת, אנא פני/ה למוקד התמיכה", 'top', 'center');
                    }
                }).catch((error) => {
                    console.log(error);
                    displaySnackbar("error", "בעיה בעת התחברות משתמש, אנא פנה/י למוקד התמיכה", 'top', 'center');
                });;

            }
            //await sleep(8000);
            if (btnRef.current)
                btnRef.current.disabled = false;
            setLoading(false);
        }
        catch (error) {
            displaySnackbar("error", "בעיה בעת התחברות משתמש, אנא פנה/י למוקד התמיכה", 'top', 'center');
            console.log(error);
            if (btnRef.current)
                btnRef.current.disabled = false;
            setLoading(false);
        }
    };

    const form = () => {
        return <FormContainer onSubmit={handleOnSubmit}
            defaultValues={{}}>
            {
                ({ values, submited, valid, validationState, reValidateField, resetField }) => {
                    return (
                        <>
                            {!isMobile &&
                                <>
                                    <div className='iconSignin iconSigninsms'>
                                        <img src={usersms} />
                                    </div>
                                </>
                            }
                            <div className="formContainer forgotpass">
                                <fieldset>
                                    <legend>שכחתי סיסמה</legend>
                                    <div className="formRow cols">
                                        <FormField
                                            type="text"
                                            name="userKey"
                                            label="קוד משתמש"
                                            id="Mod-userKey"
                                            Component={FormTextField}
                                            variant="standard"
                                            required
                                            validators={[require, length]}
                                            inputProps={{ maxLength: 8 }}
                                            minLength={8}
                                            className={clsx("formField")}
                                        />
                                        <FormField
                                            type="text"
                                            name="MODID"
                                            label="ת.זהות"
                                            id="Mod-MODID"
                                            Component={FormTextField}
                                            variant="standard"
                                            required
                                            validators={[onlyNumbers, length, require]}
                                            className={clsx("formField")}
                                            minLength={9}
                                            checkonlynumbers={'true'}
                                            inputProps={{ maxLength: 9 }}
                                        />
                                    </div>
                                    <div className='formRow'>
                                        <FormField
                                            type="text"
                                            name="MODEmail"
                                            label="כתובת מייל"
                                            id="Mod-Email"
                                            Component={FormTextField}
                                            variant="outlined"
                                            required
                                            validators={[email]}
                                            className={clsx("formField")}
                                        />
                                    </div>
                                </fieldset>

                                <div className="submitWrap">
                                    <button ref={btnRef} type="submit" name="submit" className="submitBut" >המשך</button>
                                </div>
                            </div>
                        </>
                    )
                }
            }
        </FormContainer>
    }

    return (<div className='logincontainer oneform'>
            {form()}

        </div>
    )
});
export default ForgotPassword;