import React, { useState, useEffect, useRef, useContext } from 'react';
import { AppContext } from '../../../AppContext';
import modlog from '../../../assets/images/mod.png';
const LoginHeader = (props) => {
    const { Dispatcher } = useContext(AppContext);
    useEffect(() => {
        if(Dispatcher != null)
        {
           
            //console.log(`loginHeader dispatcherDTO`, Dispatcher);
            //console.log(`Dispatcher.siteLogo.includes('http')`, Dispatcher.siteLogo.includes('http') );
        }
        // if (Dispatcher == null)
        //     dispatcherHandler();

    }, [Dispatcher]);
    return (<header>
        {Dispatcher &&
            <>
                <a href={`${Dispatcher.protocol}://www.mod.gov.il`}><img className='imgLogo img-fluid pr-3 pt-3' src={modlog} /></a>
                <a href={`${Dispatcher.protocol}://${Dispatcher.rootUrl}`}>
                    {Dispatcher.siteLogo.includes('http') 
                        ? <img className='imgLogo sitelogo img-fluid pt-3 w-100' src={Dispatcher.siteLogo} />
                        : <img className='imgLogo sitelogo img-fluid pt-3 w-100' src={`${process.env.PUBLIC_URL}${Dispatcher.siteLogo}`} />
                    }
                </a>
            </>}
    </header>
    );
};
export default LoginHeader;