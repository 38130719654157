
import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { AppContext } from './AppContext';
import api from './webApi';

const AppProvider = props => {
    const [Dispatcher, setDispatcher] = useState(null);
    const [snackBarProps, setSnackBarProps] = useState({ display: false, variant: 'info', message: '', vertical: '', horizontal: '' });
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    useEffect(() => {
        //var isStatusok = window.location.pathname.toLowerCase().indexOf("status")>0;
        if (Dispatcher == null)//(!isStatusok && Dispatcher == null)
            dispatcherHandler();
    }, [])
    const dispatcherHandler = () => {
        //console.log('AppProvider dispatcherHandler')
        fillContext();

    }
    const fillContext = async () => {
       //console.log('fill context');
        try {
            let url='';
            let rootUrl='';
            let protocol=''
            let action='';
           
            if (window.location.pathname.indexOf("Dispatcher") <= 0 && window.location.pathname.toLowerCase().indexOf("registration")>0) {
            
                 rootUrl = window.location.host;
                 //console.log('provider Registration:'+rootUrl);
            }
            else {
                 url = new URLSearchParams(window.location.search).get('URL');
                 rootUrl = new URLSearchParams(window.location.search).get('h');
                 protocol = new URLSearchParams(window.location.search).get('PROTOCOL');
                 action = new URLSearchParams(window.location.search).get('TAM_OP');
            }

            if ((rootUrl && rootUrl.length > 0) || (action && (action.toLocaleLowerCase() == 'error' || action.toLocaleLowerCase()=='logout'))) {
                const body = {
                    siteUrl: rootUrl
                }
                //const baseURL = `${process.env.REACT_APP_API_BASE_URL}api/`;
                //const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}api/ModLogin/GetSiteStyle`, { method: 'post', body: body, headers: { 'Content-Type': 'application/json' } }).then(response => {
                const res = await api.post(`ModLogin/GetSiteStyle`, body).then(response => {
                    if (response && response.data && response.data.status) {
                        let siteLogo = response.data.Data.SiteLogo;
                        let isLinkToMainSite = response.data.Data.IsLinkToMainSite;
                        let siteName = response.data.Data.SiteName;
                        let RegistrationUrl = response.data.Data.RegistrationUrl;
                        let PersonalAreaUrl = response.data.Data.PersonalAreaUrl;
                        let RegistrationText =response.data.Data.RegistrationText;
                        let favIconpath = response.data.Data.FavIcon;
                        let InfoText = response.data.Data.InfoText
                        const dispatcherDTO = {
                            url: url,
                            rootUrl: rootUrl,
                            protocol: protocol,
                            action: action,
                            siteLogo: siteLogo,
                            isLinkToMainSite: isLinkToMainSite,
                            siteName: siteName,
                            goToRegistration: RegistrationUrl ? RegistrationUrl : '',
                            RegistrationText:RegistrationText?RegistrationText:'הרשמה לאיזור האישי',
                            PersonalAreaUrl:PersonalAreaUrl,
                            InfoText :InfoText?InfoText:''
                        }
                        setDispatcher(dispatcherDTO);
                        //console.log(`dispatcherDTO`,dispatcherDTO);
                        const faviconel = document.getElementById("favicon");
                        faviconel.setAttribute("href",favIconpath);
                    }
                });
                
            }
            else {
                // console.error('dispatcher failed');
                // history.push({
                //     pathname: "./Page404",
                // });
                // window.location.href ='./Page404';
                 history.push({
                    pathname: "./",
                });
            }
        } catch (error) {
            console.error('dispatcher failed', error);
            history.push({
                pathname: "./Page404",
            });
        }
    }
    const displaySnackbarHandler = (variant, message = '', vertical = '', horizontal = '') => {
        setSnackBarProps({ display: true, variant: variant, message: message, vertical: vertical, horizontal: horizontal });
        setLoading(false);
        setTimeout(() => {
            setSnackBarProps({ display: false, variant: variant, message: message, vertical: vertical, horizontal: horizontal });
        }, 3000);
    }
    return <AppContext.Provider value={AppContext} value={{ Dispatcher, dispatcherHandler,displaySnackbarHandler,snackBarProps,setLoading,loading }}>

        {props.children}
    </AppContext.Provider>
}
export default AppProvider;