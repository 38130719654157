import React, { Fragment } from "react";
import clsx from "clsx";
import {
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
const styles = (theme) => ({
  FormRadio: {
  }
})
class FormRadio extends React.Component {
  render() {
    const { name, radioOptions, validationText, validating, callValidationEvent, onChange, value, label, error, className, classes, ...otherProps } = this.props;
    return (
      <FormControl component="fieldset" className={clsx(className)}  {...otherProps}>
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup aria-label={label} value={value} onChange={e => {
          onChange(e.target.value)
          callValidationEvent(e.target.value)
        }}>
          {radioOptions.map(option => (
            <Fragment key={option.id}>
              <FormControlLabel value={option.id} control={<Radio />} label={option.title} />
              {option.helperText ? <FormHelperText>{option.helperText}</FormHelperText> : null}
            </Fragment>
          ))}
        </RadioGroup>
      </FormControl>
    );
  }
}
export default React.memo(withStyles(styles)(FormRadio));
