import React from 'react';
import modlog from '../assets/images/mod.png';
import imgrobot from '../assets/images/404.gif';
const Page404 = React.memo(props => {
    return (<>
        <header>
            <a href='https://www.mod.gov.il'><img className='imgLogo img-fluid pr-3 pt-3' src={modlog} /></a>
        </header>
        <div className='loginwrapper'>
            <div className="ModLogin pagenotfound">
                <img src={imgrobot}/>
                <h2>כניסה לאיזור אישי</h2>
                <h3>הדף לא נמצא</h3>

            </div>
        </div>
    </>
    )
});
export default Page404;