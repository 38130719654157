export default  {
    BusinessCategory:[
        {
            "id": 'שם בית הספר היסודי בו למדת',
            "title":'שם בית הספר היסודי בו למדת'
          },
          {
            "id": 'שם הנעורים של אמך',
            "title":'שם הנעורים של אמך'
          },
          {
            "id": 'שם העיר בה נולדת',
            "title":'שם העיר בה נולדת',
          },
          {
            "id": 'שם חיית המחמד הראשונה שלך',
            "title":'שם חיית המחמד הראשונה שלך',
          },
          {
            "id": 'מהי ארץ המוצא של אמך',
            "title":'מהי ארץ המוצא של אמך',
          }
        ]
}