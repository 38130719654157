import React, { useState, useEffect, useRef,useContext } from 'react'
import {useHistory, withRouter } from 'react-router-dom';
//import useForm from '../../formsFramework/core/useForm';
import { TextField } from "@material-ui/core";
import api from '../../../webApi';
import clsx from "clsx";
import moment from 'moment';
import './../Registration.css';
import { require, onlyNumbers, email, phoneNumber, minLength } from "../../../formsFramework/validators";
import phonePrefixes from '../../../assets/static-data/phonePrefixes';
import { FormContainer, FormField } from '../../../formsFramework';
import { FormTextField, FormDatePicker, FormSelectField } from "../../../formsFramework/MaterialComponents";
import Divider from '@material-ui/core/Divider';
import { async } from 'react-app-polyfill/node_modules/regenerator-runtime';
//import ModSnakeBar from '../../layout/ModSnakeBar/ModSnakeBar';
//import ModModal from '../../../components/layout/ModModal/ModModal';
import { CircularProgress } from "@material-ui/core";
import { Tooltip } from 'primereact/tooltip';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import { AppContext } from '../../../AppContext';

const Registration = React.memo(props => {

  //const [snackBarProps, setSnackBarProps] = useState({ display: false, variant: 'info', message: '', vertical: '', horizontal: '' });
  const [displayModal, setDisplayModal] = useState(false);
  //const [disableButReg,setDisableButReg] = useState(true);
  const { displaySnackbarHandler,setLoading} = useContext(AppContext);
  //const [loading, setLoading] = useState(false);
  let btnRef = useRef();
  const history = useHistory();

  const sleep = async (time) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, time)
    })
  }


  const handleOnSubmit = async (validatioState, form) => {
    //btnRef.current.disabled = true;
    setLoading(true);
    if (validatioState) {

      const HaimashDTO = {
        //ImporterNumber : form.ImporterNumber,
        Id: form.MODID,
        // IdCardDate : ( form.MODIDDate != undefined || form.MODIDDate != null) ? (moment(form.MODIDDate).format('DD/MM/YYYY')) : null,
        PhonePrefix: form.PhonePrefix,
        PhoneNumber: form.PhoneNumber,
        FirstName: form.FirstName,
        LastName: form.LastName,
        Email: form.MODEmail,
        PersonalNumber: form.PersonalNumber,
        BankAccount: form.BankAccount,
        MisparIshi: form.MisparIshi

      }
      const res = await api.post(`ModLogin/HaimashRegistration`, HaimashDTO).then(response => {

        if (response && response.status == 200 && response.data && response.data.status != false) {
          //props.history.push('RegistrationStatus?status=0');
          //window.location.href = 'RegistrationStatus?status=0'
          history.push({
            pathname: "/RegistrationStatus",
            state: { status: 0 }
          });
        }
        else {
          if (response.data && !response.data.status && response.data.ErrorType == 0) {

            displaySnackbar("error", "משתמש לא קיים במערכת, אנא פנה למוקד התמיכה", 'top', 'center');
          }
          else if (response.data && !response.data.status && (response.data.ErrorType == 1 || response.data.ErrorType == 2)) {
            displaySnackbar("error", "אחד הנתונים אינו תואם לנתונים הקיימים במערכת", 'top', 'center');

          }
          else if (response.data && !response.data.status && response.data.ErrorType == 4) {
            //TODO new user exist page
            //props.history.push('/UserExist');
            //window.location.href = 'UserExist';
            history.push({
              pathname: "/UserExist"
            });
          }
          else {
            //props.history.push('RegistrationStatus?status=1');
            //window.location.href = 'RegistrationStatus?status=1'
            history.push({
              pathname: "/RegistrationStatus",
              state: { status: 1 }
            });
          }

        }
      }).catch((error) => {
        console.log(error);
        displaySnackbar("error", "בעיה בעת יצירת משתמש, אנא פנה למוקד התמיכה", 'top', 'center');
      });;

    }
    //await sleep(8000);
    //btnRef.current.disabled = false;
    setLoading(false);
  };

  const bankDetailsChange = (newValue,validationResult, reValidateField,resetField)=>
  {
    if(newValue != null && newValue != undefined && newValue.length > 0)
    {
      resetField({ name: "MisparIshi", validationRules: [], isArray: false });
      reValidateField( "BankAccount","מספר חשבון בנק");
    }
  }
  const MisparIshiChange = (newValue,validationResult, reValidateField,resetField)=>
  {
    if(newValue != null && newValue != undefined && newValue.length > 0)
    {
      resetField({ name: "BankAccount", validationRules: [], isArray: false });
      reValidateField( "MisparIshi","מספר אישי");
    }
    else
    {
      resetField({ name: "BankAccount", validationRules: [onlyNumbers,require,minLength], isArray: false });
    }
  }
  function handleOnClose (){
     // setShowModal(false);
    }

const displaySnackbar = (variant,message='',vertical='',horizontal='')=>{
  displaySnackbarHandler(variant, message, vertical, horizontal);
}

  return (
    <div className="ModRgistration logincontainer oneform Hachvana">
      <FormContainer onSubmit={handleOnSubmit}
        defaultValues={{}}>
        {
          ({ values, submited, valid, validationState, reValidateField, resetField }) => {
            return (
              <div className="formContainer">
                <fieldset>
                  <legend>הרשמה</legend>
                  {/* <h3>פרטים אישיים</h3> */}
                  <Tooltip target=".before_start" hideDelay={1000} position="left" autoHide={false} className="hachvana-tooltip" >

                    <div className="p-d-flex p-ai-center" style={{ maxWidth: "250px" }}>
                      <div><b>עדיין בשירות?</b> .חכה/י לשחרור, רק אז תוכל/י להירשם</div>
                      <div><b>בכל זאת לא מצליח/ה?</b>תתקשר/י אלינו- *5266, עומדים לשירותכם בימי א-ה בשעות 08:0-16:00</div>
                    </div>
                  </Tooltip>
                  <div className="before_start p-ml-2">שניה לפני שמתחילים</div>
                  <Divider variant="middle" />
                  <div className="formRow cols">
                    <FormField
                      type="text"
                      name="FirstName"
                      label="שם פרטי"
                      id="Mod-FirstName"
                      Component={FormTextField}
                      variant="outlined"
                      required
                      validators={[require]}
                      className={clsx("formField")}
                    />
                    <FormField
                      type="text"
                      name="LastName"
                      label="שם משפחה"
                      id="Mod-LastName"
                      Component={FormTextField}
                      validators={[require]}
                      required
                      variant="outlined"
                      className={clsx("formField")}
                    />
                  </div>
                  <div className="formRow cols">
                    <FormField
                      type="text"
                      name="MODID"
                      label="תעודת זהות"
                      id="Mod-MODID"
                      Component={FormTextField}
                      variant="outlined"
                      required
                      validators={[onlyNumbers, minLength, require]}
                      className={clsx("formField")}
                      minLength={9}
                    />
                    <FormField
                      type="text"
                      name="MODEmail"
                      label="כתובת מייל"
                      id="Mod-Email"
                      Component={FormTextField}
                      variant="outlined"
                      required
                      validators={[email]}
                      className={clsx("formField")}
                    />
                  </div>
                  <div className="formRow cols phonecol">
                    <div className="phoneSection">
                      <FormField
                        type="text"
                        name="PhoneNumber"
                        label="טלפון נייד"
                        id="Mod-PhoneNumber"
                        Component={FormTextField}
                        variant="outlined"
                        required
                        validators={[onlyNumbers, minLength, require]}
                        className={clsx("formField", "formFieldPhone")}
                        minLength={7}
                        inputProps={{ maxLength: 7 }}
                      />
                      <FormField
                        name="PhonePrefix"
                        Component={FormSelectField}
                        variant="outlined"
                        label="קידומת נייד"
                        id="Mod-PhonePrefix"
                        className="prefix-phone"
                        validators={[onlyNumbers, require]}
                        className={clsx("formField", "formFieldPhonePrefix")}
                        inputProps={{ maxLength: 3 }}
                        required
                        selectValues={phonePrefixes.mobilePrefix}
                      />
                    </div>
                  </div>
                  <div ><h3>יש למלא מספר אישי צבאי</h3> </div>
                  <div className="formRow cols">
                    <FormField
                      type="text"
                      name="MisparIshi"
                      label="מספר אישי"
                      Component={FormTextField}
                      variant="outlined"
                      validators={[onlyNumbers, require, minLength]}
                      className={clsx("formField")}
                      minLength={6}
                      inputProps={{ maxLength: 9 }}
                      valueChanged={(newValue, validationResult) => MisparIshiChange(newValue, validationResult, reValidateField, resetField)}
                    />
                  </div>
                  <div className='pb-2'><b><u>או</u></b></div>
                  <div><h3> 4 ספרות אחרונות של חשבון הבנק בסיום השירות הסדיר</h3></div>
                  <div className="formRow cols">
                    <FormField
                      type="text"
                      name="BankAccount"
                      label="מספר חשבון בנק"
                      id="Mod-BankAccount"
                      Component={FormTextField}
                      variant="outlined"
                      validators={[onlyNumbers, require, minLength]}
                      className={clsx("formField")}
                      minLength={4}
                      inputProps={{ maxLength: 4 }}
                      valueChanged={(newValue, validationResult) => bankDetailsChange(newValue, validationResult, reValidateField, resetField)}
                    />
                  </div>
                </fieldset>
                <div className="submitWrap  pb-5">
                  <button ref={btnRef} name="submit" className="submitBut" >שלח</button>
                </div>
              </div>
            )
          }
        }
        {/* </div> */}
      </FormContainer>

    </div>
  );
});
export default Registration;
