import React,{useEffect} from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
const styles = theme => ({
  select: {
    textAlign: "left"
  }
});
const FormSelectField = props => {
  const {
    name,
    validationText,
    callValidationEvent,
    value,
    onChange,
    required,
    placeholder,
    label,
    selectValues,
    classes,
    validating,
    customFieldNames,
    helperText,
    FormHelperTextProps,
    ...otherProps
  } = props;
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
 
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);   
   // return ()=>{console.log(`${name} select unmount`)} 
  }, []);
  let fieldNames = customFieldNames;
  if(!customFieldNames){
    fieldNames = {id:'id',title:'title'}
  }
  let mixedHelperText = validationText ? validationText : helperText ? helperText : '';  
  
  return (
    <FormControl required margin="normal" {...otherProps}>
      <InputLabel ref={inputLabel}>{label}</InputLabel>
      <Select
        className={classes.select}
        value={value}
        onChange={e =>{
          onChange(e.target.value)
          callValidationEvent(e.target.value)
        }}
        onBlur= {e => callValidationEvent(e.target.value)}
        
        input={<OutlinedInput labelWidth={labelWidth} name={name} aria-describedby={name+"-helper-text"} aria-invalid={mixedHelperText ? true : false}/>}//callValidationEvent(e.target.value)} />}
      >
        <MenuItem value={value}>
        {value ?
        //  <em>{value.title}</em>
         <em>{value}</em>
        :
        <em>בחר</em>
        }
        </MenuItem>
        {selectValues &&
          selectValues.map(value => (            
            <MenuItem key={value[fieldNames.id]} value={value[fieldNames.id]}>
               {value[fieldNames.title]}
             </MenuItem>
          ))}
      </Select>
      <FormHelperText {...FormHelperTextProps}  id={name+"-helper-text"}>{mixedHelperText}</FormHelperText>
    </FormControl>
  );
};
export default React.memo(withStyles(styles)(FormSelectField));
