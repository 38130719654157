import React,{useState,useEffect} from "react";
import FormContext from "./formContext";
import _ from 'lodash';
import { values } from "react-app-polyfill/node_modules/regenerator-runtime";
//import { setFlagsFromString } from "v8";
//const FormContext = React.createContext();
const FormContainer  =   React.memo(props =>{
  var initialForm = props.defaultValues || {};
  const [form,setForm] = useState(initialForm);
  const [updateMode,setUpdateMode] = useState(updateMode);
  const [validationState,setNewValidationState] = useState({});
  const [generalValidationResult,setGeneralValidationResult]  = useState({});
  const [touchedState,setNewTouchedState]= useState({});
  const [submited,setSubmited] = useState(false);
  const [valid,setValid] = useState(true);

  // useEffect(()=>{
  //   var initialForm = props.defaultValues || {};
  //   if (
  //     props.udpateModeValues &&
  //     Object.keys(props.udpateModeValues).length > 0
  //   ) {
  //     updateMode = true;
  //     for (const prop in props.udpateModeValues) {
  //       initialForm[prop] = props.udpateModeValues[prop] || (props.defaultValues && props.defaultValues[prop]) || null;
  //     }
  //   }
  //   setForm(initialForm);
  // },[]);

  useEffect(()=>{
    const validForm = _.some(validationState,['valid',false]);
   setValid(!validForm);

  },[validationState]);

  
  const getFieldValue = ({ name }) => {
    const fieldValue = _.get(form, name, '');
    return fieldValue;
  };

  const getFieldValidationState = ({ name }) => {
      const fieldValidationState = validationState[name];
      return fieldValidationState;
  };
  const getValidationState = () => {
    return validationState; 
  };
  const getTouchedState = () => {
    return touchedState;
  };
  const getFieldTouchedState = ({ name }) => {
    return touchedState[name];
  };
 const  setFieldValue = async (fieldName, newValue, leaveUntouched = false, valueChanged = null) => {
    let newFieldState = newValue;
    let stateFieldName = fieldName;
    // support arrays of fields
    const indexNumber = fieldName.match(/\[([0-9]+)\]/);
   
    if (indexNumber) {
      const arrayFieldName = fieldName.slice(0, fieldName.indexOf("["));
      const innerFieldName = fieldName.slice(fieldName.indexOf("]") + 2);
      let clonedArrayItem = //{
        form[arrayFieldName][indexNumber[1]];
        //...this.state.form[arrayFieldName][indexNumber[1]]
       
     // }
      _.set(clonedArrayItem, innerFieldName, newValue);
      //newFieldState = this.state.form[arrayFieldName].map((item, index) => {
     newFieldState = form[arrayFieldName].map((item, index) => {
        return index == indexNumber[1] ? clonedArrayItem : item
      });
      stateFieldName = arrayFieldName;
    }
   
    setForm({...form,[stateFieldName]: newFieldState});
    //console.timeEnd('setFieldValue');
  };
const setTouchedState = newTouchedState => {
    setNewTouchedState(newTouchedState)
  };
  const setFieldTouchState = (fieldName, touched = true) => {
    setTouchedState({...touchedState,[fieldName]:touched})
  }
const  setValidationState = newValidationState => {
    setNewValidationState(newValidationState);
  };

  const setFieldValidationState = (
    fieldName,
    validationState,
    validators = []
  ) => {
    //let valid = validationState ? validationState.valid : true;
    // this.setState(state => ({
    //   validationState: {
    //     ...state.validationState,
    //     [fieldName]: { ...validationState, validators: validators }
    //   }
    //   //,valid:  valid
    // }));
    //let newvalidators = validationState[fieldName].validators

    setNewValidationState(prevState => ({
      ...prevState,
        [fieldName]: { ...validationState,validators}}
  ));
  //setNewValidationState(...validationState,[fieldName]:{...validationState,validators});
  //setNewValidationState({...validationState,[fieldName]:{...validationState,validators}});
  };
 const validateField = async (validationRules = [], newValue, fieldName,label="",minLength=0) => {
   // console.log(validationRules);
  
    let validationResult = await Promise.all(
      validationRules.map(validationFunction => validationFunction(newValue, fieldName,label,minLength, form))
    ).catch(e => {
      console.log(`error : ${e.message}`)
    }) 
 
    const validationAggrigation = validationResult.reduce((status, validatorResult) => {
      if (!status.valid) {
       // validForm = false;
        return status;
      }
      return {
        valid: validatorResult.valid && status.valid,
        validationText: validatorResult.valid ? status.validationText
          : status.validationText ? `${status.validationText}, ${validatorResult.validationText}`
            : validatorResult.validationText
      };
    }, { valid: true, validationText: "" })
    setFieldValidationState(fieldName, validationAggrigation, validationRules,label);
    return validationAggrigation;
  };

  const reValidateField = (fieldName,label="") => {
    const fieldValidationState = validationState[fieldName];
    const fieldValue = getFieldValue({name:fieldName});
    if (fieldValidationState) {
      validateField(fieldValidationState.validators, fieldValue, fieldName,label)
    }
  }
  const generalValidation = async (validationRules = [], formValues) => {
    let validationResult = await Promise.all(
      validationRules.map(validationFunction => {
        validationFunction(formValues);
      })
    ).catch(e => {
      console.log(`error : ${e.message}`)
    })
    
    const validationAggrigation = validationResult.reduce((status, validatorResult) => {
      return {
        valid: validatorResult.valid && status.valid,
        validationText: !validatorResult.valid ?
          status.validationText !== "" ?
            `${status.validationText}, ${validatorResult.validationText}` : `${validatorResult.validationText}`
          : `${status.validationText}`
      };
    }, { valid: true, validationText: "" })
    return validationAggrigation;
  }
  const onSubmit = async event => {
    // validate all forms field and set validation status
    
    event.preventDefault();
    const { generalValidators } = props;
    let valid = Object.keys(validationState).reduce(
      (accu, field) => accu & validationState[field].valid,
      true
    );
   
    if (generalValidators) {
      const generalValidationResult = await generalValidation(generalValidators, form);
      valid = valid && generalValidationResult.valid;
    setGeneralValidationResult(generalValidationResult);
    }
    // set submited to true
    //this.setState({ valid: valid, submited: true });
    setValid(valid);
    setSubmited(true);
    if (props.onSubmit) {
      props.onSubmit(valid, form);
    }
  };
  const resetField = async ({
    name,
    newValue = "",
    label,
    validationRules = [],
    isArray = false
  }) => {
    // In reset process we calculate all validation state from start
    // we preserve all the other fields state but reset the selected field
    // selected  field - we update the validators and update the form validation state
    // if the field is an array then we delete all his enteries in the validationState
    // after the validationState is set we update the field with his new values
    
    // First Step - Update validators
     //TODO------------
    // this.setState(
    //   newState => {
    //     const newValidationState = Object.keys(newState.validationState).reduce(
    //       (accu, key) => {
    //         let NameToSearch = isArray ? `${name}[` : `${name}`;
    //         if ((isArray && !key.includes(NameToSearch)) || (!isArray && NameToSearch !== key)) {
    //           // preserve the current validation state
    //           accu[key] = newState.validationState[key];
            
    //         } else {
    //           // this is the field we want to reset
    //           // array fields will be deleted from validation state
    //           // selected field will be updated by his new validators
    //           if (!isArray) {
    //             accu[key] = accu[key] || {};
    //             accu[key].validators = validationRules;
    //           }
    //         }

    //         return accu;
    //       },
    //       {}
    //     );
    //     return {
    //       validationState: newValidationState
    //     };
    //   }

    // );
    //console.log(validationRules);
    //console.log(newValue);
    setNewValidationState({...validationState,[name]:validationRules});
    //Update Field values
   // setFieldValue(name, newValue, true);
   
    // validate field
    await validateField(validationRules, newValue, name,label);
    

    // handle touch state for the selected field
    // we remove all the fields for array and the selected field if it is not an array

    ////TODO------------
    // this.setState(newState => {
    //   const newTouchedState = Object.keys(newState.touchedState).reduce(
    //     (accu, key) => {
    //       let NameToSearch = isArray ? `${name}[` : `${name}`;
    //       if (!key.includes(NameToSearch)) {
    //         accu[key] = newState.touchedState[key];
    //       }
    //       return accu;
    //     },
    //     {}
    //   );
    //   return {
    //     touchedState: newTouchedState
    //   };
    // });
  //};
  // render() {
  //   const { displayFormValues =true } = this.props;
  };
  const displayFormValues =false;
    return (
      <FormContext.Provider
        value={{
          values: form,
          submited:submited,
          getFieldValue: getFieldValue,
          getFieldValidationState: getFieldValidationState,
          getValidationState: getValidationState,
          getTouchedState: getTouchedState,
          getFieldTouchedState: getFieldTouchedState,
          setFieldValue: setFieldValue,
          setFieldValidationState: setFieldValidationState,
          validateField: validateField,
          setValidationState: setValidationState,
          setTouchedState: setTouchedState,
          setFieldTouchState: setFieldTouchState,
          reValidateField:reValidateField
        }}
      >
        <form noValidate onSubmit={onSubmit}>
          {props.children({
            values: form,
            submited: submited,
            valid: valid,
            resetField:resetField,
            getValidationState: getValidationState,
            getFieldValidationState: getFieldValidationState,
            reValidateField: reValidateField,
            generalValidationResult: generalValidationResult,
            getFieldValue: getFieldValue
          })
          }
          {
            displayFormValues ? (
              <pre style={{ direction: "ltr", textAlign: "left", marginTop: "101px" }}>
                <code>{JSON.stringify({"form":form,"validationState":validationState,"valid":valid,"values": values,"touchedState:":touchedState}, null, 4)}</code>
              </pre>
            ) : null
          }
        </form>
      </FormContext.Provider>
   );
  });
export default FormContainer;
/**
  accepted props :  onSubmit - this function will be triggered on subite event. with(valid, this.state.form)
                    children - one function. the function will get(values,submited,valid)
  context - this component will be the container of the FormContext. the context will have:
            {
              form: {},
              validationState: {},
              touchedState: {},
              submited: false,
              valid: true
          };
 */
