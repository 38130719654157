import React, {Fragment,useContext} from 'react';
import { AppContext } from '../../AppContext';
import { useHistory, Link } from 'react-router-dom';

const UserExist = React.memo(props =>{
    const { Dispatcher } = useContext(AppContext);
//const smsLink = Dispatcher.PersonalAreaUrl;//window.SMSPageUrl;
return (
    <Fragment>
        <div>שלום רב,</div>
        <div>נמצא כי הנך רשום/ה במערכת</div>
        <div  className="telSection">לצורך קבלת פרטי הרישום ניתן לפנות אל מרכז השירות הטלפוני:
            <a href="tel:03-7776700">03-7776700</a>
            <span>03-7776700</span>
        </div>
        {Dispatcher &&<div>
            או להיכנס באמצעות כניסה מהירה ב -
            <a href={ Dispatcher.PersonalAreaUrl} target="_balnks" >SMS</a>
            
       </div>}
   </Fragment>
);
});
export default UserExist;