import React, { useEffect, useState,useContext } from 'react';
import { useLocation,useHistory, Link } from 'react-router-dom';
import moment from 'moment';
import "moment/min/locales";
//import { Link } from 'react-router-dom/cjs/react-router-dom';
import { AppContext } from '../../AppContext';

const RegistrationStatus = React.memo((props) => {
    const { Dispatcher } = useContext(AppContext);
    const history = useHistory();
    const location = useLocation();
    const [status, setStatus] = useState(0);

    useEffect(() => {
        try {
            if (location.state == undefined || location.state.status == undefined) {
                history.push("/");
            }
            else {
                setStatus(location.state.status);
            }
            // const params = new URLSearchParams(props.location.search);
            // if (params) {
            //     setStatus(params.get('status'));
            // }
            // console.log(params.get('status'));
        } catch (e) {
            console.error(e);
            setStatus(1)
            //window.location.href = Dispatcher.PersonalAreaUrl;//"/login";
        }
    }, []);

    const handleOnClick = () => {
        window.top.location.href = Dispatcher.PersonalAreaUrl;
        //history.push("/");
        // if(window.location.href.indexOf("hachvana")  > 0)
        // {
        //     window.top.location.href = "/OnlineService";
        // }
        // else{

        // window.top.location.href = "/Personal";
        //}
    }
    // const handleClick
    return (
        <div className="RegistrationStatus">

            <div>
                {status == 0 ?
                    <h3>הרישום לאתר בוצע בהצלחה!</h3>
                    :
                    <h3>אירעה שגיאה במהלך הרישום, אנא נסי/ה במועד מאוחר יותר</h3>
                }
            </div>
            <div className="RegistrationStatusDetails">
                {status == 0 &&
                    <div className="detailesBox">                        
                        {/* בדקות הקרובות תשלח אלייך הודעת SMS עם קוד המשתמש שלך וסיסמה זמנית.
                        יש להכניס את הפרטים שהתקבלו בהודעה,במסך הבא. */}
                        בדקות הקרובות תשלח אלייך הודעת SMS אישור.
                    </div>
                }
                <button onClick={handleOnClick}>בחזרה למסך כניסה</button>
            </div>

        </div>
    );
});


export default RegistrationStatus;