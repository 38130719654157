import './assets/Style/bootstrap4-rtl.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './assets/fonts/opensanshebrew.css'
import React from 'react';
import ReactDOM from 'react-dom';
 import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';


const app = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
ReactDOM.render(app, document.getElementById('ModLoginApp'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
