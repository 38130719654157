import React,{useState,useContext,useEffect} from "react";
import { TextField } from "@material-ui/core";
import FormContext from "./formContext";

const FormField = (props)=>{
  const context = useContext(FormContext);
  const [validating,setvalidating]= useState(false);
  const {
    validateField,state,
    getFieldValue,setFieldValue,
    getTouchedState,getFieldTouchedState,
    setFieldTouchState,getFieldValidationState } = context;//useContext(FormContext);

  useEffect(()=>{

     // initiate field in the form context: this happens only once!!
    //    1. get default value - the default value is set in FormContainer - getFieldValue will bring it for validation
    //    2. initialize validation state - build validation entery for the field:
    //    validators - comes from props
    //    validation status - validate the field by his validators
    let { name,label, validators = [] } = props;

      const newValue  =  getFieldValue({ name });
        //update validation state
      const validationResult = //async()=>{ 
        //await 
        validateField(validators, newValue, name,label)
     // };
  },[name]);

  const callValidationEventHandler = async(validators, newValue, filedName,label, valueChanged) =>{
    setvalidating(true);
    let minLength = 0;
    if(  Object.keys(otherProps).includes("minLength") )
    {
      minLength= otherProps.minLength;
    }

    //const validationResult = await validateField(validators, newValue, label,minLength);
    const validationResult = await validateField(validators, newValue, filedName,label,minLength);
    setFieldTouchState(filedName);
    if (valueChanged) {
      valueChanged(newValue, validationResult);
    }
   setvalidating(false);
  }

    const {
      name,
      initialValue,
      validators,
      Component,
      valueChanged,
      ...otherProps
    } = props;
   // const { validating } = this.state;
    return (
      <FormContext.Consumer>
        {context => {
          const { state, submited,setFieldValue, getFieldValue, getFieldLabel,validateField, getFieldValidationState, getFieldTouchedState } = context;
          const value = getFieldValue({ name }) || "";
          const label = otherProps.label;
          const validationState = getFieldValidationState({ name });
          const touchedState = getFieldTouchedState({ name });
          const error =
          validationState &&
          !validationState.valid &&
          (touchedState || submited);
        const validationText =
          validationState &&
          (touchedState || submited) &&
          validationState.validationText;
           // console.log(name);
           // console.log(validationText);
          const onChange = newValue => {
            setFieldValue(name, newValue, false, valueChanged)
          };
          const callValidationEvent = newValue => {
            if(validationState!=undefined)
            callValidationEventHandler(validationState.validators, newValue, name,label,valueChanged)
          }
          return typeof props.children !== "function" ? (
            <Component
              {...otherProps}
              onChange={onChange}
              callValidationEvent={callValidationEvent}
              value={value}
              name={name}
              validationText={validationText}
              validating={validating}
              
            />
          ) : (
              props.children({
                onChange,
               // callValidationEvent,
                value,
                error,
                name,
                validationText,
                ...otherProps
              })
            );
        }}
        
      </FormContext.Consumer>
    );
  //}
}
//FormField.contextType = FormContext;
export default FormField;
/**
  accepted props :
      name - the name of the form field
      initialValue- array with objects, each object contains the fields of the array row
      validators - array of validators function
      Component - this component will be rendered. it will get ll the props:
          error - bool
          validationText- text to display to the user on error
         value - value of the form field
 */
