import React from 'react';
import modlog from '../assets/images/mod.png';
import imgrobot from '../assets/images/error.gif';
const ErrorPage = React.memo(props => {
    return (<>
    <div className='errorpage'>
        <img src={imgrobot}/>
        <div className='loginwrapper'>
            <div className="ModLogin pagenotfound">
                <h2>אופס ארעה שגיאה</h2>
            </div>
        </div></div>
    </>
    )
});
export default ErrorPage;