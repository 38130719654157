import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import './App.css';
import api from './webApi';
import RoutingManager from './containers/RoutingManager/RoutingManager';
import Registration from './components/Registration/Registration';
import materialStyle from './hoc/MaterialStyle';
import AppProvider from './AppProvider';
import { ContactSupportOutlined } from '@material-ui/icons';
import LoginHeader from './components/layout/Header/LoginHeader';
//import ModSnakeBar from './components/layout/ModSnakeBar/ModSnakeBar';
import MainLayout  from './components/layout/MainLayout';
import { CircularProgress } from "@material-ui/core";

function App() {
  //const [snackBarProps, setSnackBarProps] = useState({ display: false, variant: 'info', message: '', vertical: '', horizontal: '' });
  //const [loading, setLoading] = useState(false);
  return (
    <div className="App">     
      <AppProvider>
        <MainLayout>
          <RoutingManager></RoutingManager>
        </MainLayout>
      </AppProvider>
    </div>
  );
}
export default materialStyle(App);