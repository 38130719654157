import React, { useState, useEffect, useRef, useContext } from 'react'
import { useHistory, Link } from 'react-router-dom';
import useForm from '../../formsFramework/core/useForm';
import { TextField } from "@material-ui/core";
import api from '../../webApi';
import clsx from "clsx";
import moment from 'moment';
import './Registration.css';
import { require, onlyNumbers, email, phoneNumber, minLength } from "../../formsFramework/validators";
import phonePrefixes from '../../assets/static-data/phonePrefixes';
import { FormContainer, FormField } from '../../formsFramework';
import { FormTextField, FormDatePicker, FormSelectField } from "../../formsFramework/MaterialComponents";
import Divider from '@material-ui/core/Divider';
import { async } from 'react-app-polyfill/node_modules/regenerator-runtime';
//import ModSnakeBar from '../layout/ModSnakeBar/ModSnakeBar';
import ModModal from '../../components/layout/ModModal/ModModal';
import { CircularProgress } from "@material-ui/core";
import { AppContext } from '../../AppContext';

const Registration = React.memo(props => {

  const [displayModal, setDisplayModal] = useState(false);
  const { displaySnackbarHandler, setLoading } = useContext(AppContext);
  const history = useHistory();
  let btnRef = useRef();

  const sleep = async (time) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, time)
    })
  }



  const handleOnSubmit = async (validatioState, form) => {
    btnRef.current.disabled = true;
    setLoading(true);
    if (validatioState) {

      // disableBut = false;
      //  props.history.push('RegistrationStatus',{data:form});
      // const res  = await api.get(`Test/SayHello`).then(response=>
      const FamilyDTO = {
        TikNumber: form.TikNumber,
        Id: form.MODID,
        IdCardDate: (form.MODIDDate != undefined || form.MODIDDate != null) ? (moment(form.MODIDDate).format('DD/MM/YYYY')) : null,
        BankAccount: form.BankAccount,
        BankCode: form.BankCode,
        PhonePrefix: form.PhonePrefix,
        PhoneNumber: form.PhoneNumber,
        FirstName: form.FirstName,
        LastName: form.LastName,
        Email: form.MODEmail

      }
      const res = await api.post(`ModLogin/FamilyRegistration`, FamilyDTO).then(response => {

        if (response && response.status == 200 && response.data && response.data.status != false) {
          //window.location.href = 'RegistrationStatus?status=0';//props.history.push('RegistrationStatus?status=0');
          setLoading(false);
          history.push({
            pathname: "/RegistrationStatus",
            state: { status: 0 }
          });
          return;
        }
        else {
          if (response.data && !response.data.status && response.data.ErrorType == 0) {
            displaySnackbar("error", "משתמש לא קיים במערכת, אנא פנה למוקד התמיכה", 'top', 'center');
          }
          else if (response.data && !response.data.status && (response.data.ErrorType == 1 || response.data.ErrorType == 2)) {
            // displaySnackbar("error", "חשבון בנק לא תואם לנתונים במערכת, אנא פנה למוקד התמיכה",'top','center');
            displaySnackbar("error", "אחד הנתונים אינו תואם לנתונים הקיימים במערכת", 'top', 'center');

          }
          //   else if(response.data && !response.data.status && response.data.ErrorType == 2) 
          //   {
          //     displaySnackbar("error", "תאריך הנפקה לא תואם לנתונים במערכת, אנא פנה למוקד התמיכה",'top','center');
          //  }
          else if (response.data && !response.data.status && response.data.ErrorType == 4) {
            // displaySnackbar("error", "הינך כבר קיים במערכת....",'top','center');
            // setTimeout(()=>{
            //  },7000)
            //window.location.href = 'UserExist';//props.history.push('/UserExist');
            setLoading(false);
            history.push({
              pathname: "/UserExist"
            });
            return;
            //window.top.location.href = "/Personal";
            //setDisplayModal(true);
          }
          else {
            //window.location.href = 'RegistrationStatus?status=1';//props.history.push('RegistrationStatus?status=1');
            setLoading(false);
            history.push({
              pathname: "/RegistrationStatus",
              state: { status: 1 }
            });
            return;
          }

        }
      }).catch((error) => {
        console.log(error);
        displaySnackbar("error", "בעיה בעת יצירת משתמש, אנא פנה למוקד התמיכה", 'top', 'center');
      });;

    }
    else{
      //form not valid
      displaySnackbar("error", "יש למלא את כל שדות הטופס", 'top', 'center');
    }
    await sleep(8000);
    btnRef.current.disabled = false;
    setLoading(false);
  };


  function handleOnClose() {
    // setShowModal(false);
  }
  /*
const bankDetailsChange = (newValue,validationResult, reValidateField,resetField)=>
{
if(newValue != null || newValue != undefined)
{
  resetField({ name: "MODIDDate", validationRules: [], isArray: false });
  resetField({ name: "BankCode", validationRules: [onlyNumbers,require,minLength], isArray: false });
  reValidateField( "BankAccount","מספר חשבון בנק");
}
}
const IDDateChange = (newValue,validationResult, reValidateField,resetField)=>
{
if(newValue != null || newValue != undefined)
{
  //resetField({ name: "MODIDDate", validationRules: [], isArray: false });
  resetField({ name: "BankAccount", validationRules: [], isArray: false });
  resetField({ name: "BankCode", validationRules: [], isArray: false });
}
else
{
  resetField({ name: "BankAccount", validationRules: [onlyNumbers,require,minLength], isArray: false });
  resetField({ name: "BankCode", validationRules: [onlyNumbers,require,minLength], isArray: false });
}
}*/
  const displaySnackbar = (variant, message = '', vertical = '', horizontal = '') => {
    //setSnackBarProps({ display: true, variant: variant, message: message, vertical: vertical, horizontal: horizontal })
    displaySnackbarHandler(variant, message, vertical, horizontal);
  }


  return (
    <div className='ModRgistration logincontainer oneform'>

      <FormContainer onSubmit={handleOnSubmit}
        defaultValues={{}}>
        {
          ({ values, submited, valid, validationState, reValidateField, resetField }) => {
            return (
              <div className="formContainer">
                <fieldset>
                  <legend>הרשמה</legend>
                  <Divider variant="middle" />
                  <div className="formRow cols">
                    <FormField
                      type="text"
                      name="FirstName"
                      label="שם פרטי"
                      id="Mod-FirstName"
                      Component={FormTextField}
                      variant="outlined"
                      required
                      validators={[require]}
                      className={clsx("formField")}
                    />
                    <FormField
                      type="text"
                      name="LastName"
                      label="שם משפחה"
                      id="Mod-LastName"
                      Component={FormTextField}
                      validators={[require]}
                      required
                      variant="outlined"
                      className={clsx("formField")}
                    />
                  </div>
                  <div className="formRow cols">
                    <FormField
                      type="text"
                      name="MODID"
                      label="תעודת זהות"
                      id="Mod-MODID"
                      Component={FormTextField}
                      variant="outlined"
                      required
                      validators={[onlyNumbers, minLength, require]}
                      //inputProps={{minLength:9}}
                      className={clsx("formField")}
                      minLength={9}
                    />
                    <FormField
                      type="text"
                      name="TikNumber"
                      label="מספר תיק"
                      Component={FormTextField}
                      variant="outlined"
                      required
                      validators={[onlyNumbers, minLength, require]}
                      className={clsx("formField")}
                      minLength={6}
                      inputProps={{ maxLength: 9 }}
                    />
                  </div>
                  <div className="formRow cols phonecol phoneTwocol">
                    <div className="phoneSection">
                      <FormField
                        type="text"
                        name="PhoneNumber"
                        label="טלפון נייד"
                        id="Mod-PhoneNumber"
                        Component={FormTextField}
                        variant="outlined"
                        required
                        validators={[onlyNumbers, minLength, require]}
                        className={clsx("formField", "formFieldPhone")}
                        minLength={7}
                        inputProps={{ maxLength: 7 }}
                      />
                      <FormField
                        name="PhonePrefix"
                        Component={FormSelectField}
                        variant="outlined"
                        label="קידומת נייד"
                        id="Mod-PhonePrefix"
                        className="prefix-phone"
                        validators={[onlyNumbers, require]}
                        className={clsx("formField", "formFieldPhonePrefix")}
                        inputProps={{ maxLength: 3 }}
                        required
                        selectValues={phonePrefixes.mobilePrefix}
                      />
                    </div>
                    <FormField
                      type="text"
                      name="MODEmail"
                      label="כתובת אלקטרונית"
                      id="Mod-Email"
                      Component={FormTextField}
                      variant="outlined"
                      required
                      //validators={[email]}
                      validators={[require]}
                      className={clsx("formField")}
                    />
                  </div>
                </fieldset>
                {/* <fieldset>
                <legend>אם הינך מקבל תלוש תגמולים יש למלא פרטי חשבון בנק</legend>
                <Divider variant="middle" />
              <div className="formRow">
              <FormField
                    type="text"
                    name="BankAccount"
                    label="מספר חשבון בנק"
                    id="Mod-BankAccount"
                    Component={FormTextField}
                    variant="outlined"
                   validators={[onlyNumbers,require,minLength]}
                    className={clsx("formField")}
                    minLength={2}
                   valueChanged={(newValue, validationResult) =>bankDetailsChange(newValue, validationResult, reValidateField, resetField)}
                  />
                   <FormField
                    type="text"
                    name="BankCode"
                    label="מספר קוד בנק כולל סניף (מופיע בתלוש תגמולים)"
                    id="Mod-BankCode"
                    Component={FormTextField}
                    variant="outlined"
                    validators={[onlyNumbers,minLength,require]}
                    className={clsx("formField")}
                    minLength={5}
                  />
              </div>
              </fieldset>
              <fieldset>
                <legend>אם אינך מקבל תלוש תגמולים יש להזין</legend>
                <Divider variant="middle" />
              <div className="formRow">
                  <FormField
                    className={clsx("formField")}
                    label="תאריך הנפקת תעודת זהות"
                    id="Mod-IDDate"
                    inputVariant="outlined"
                    placeholder="10/10/2018"
                    name={"MODIDDate"}
                    Component={FormDatePicker}
                  valueChanged={(newValue, validationResult) =>IDDateChange(newValue, validationResult, reValidateField, resetField)}
                    format="dd/MM/yyyy"
                  />
              </div>
              </fieldset> */}
                <div className="submitWrap  pb-5">
                  <button ref={btnRef} name="submit" className="submitBut" >שלח</button>
                </div>
              </div>
            )
          }
        }
        {/* </div> */}
      </FormContainer>

    </div>
  );
});
export default Registration;
