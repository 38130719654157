import React from "react";
import clsx from "clsx";
import { Switch, FormControlLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
const styles = (theme) => ({
  Switch: {   

  }
})
class FormSwitch extends React.Component {
  render() {
    const { name, validationText, validating, callValidationEvent, onChange, value,checked, label, error, className, classes, ...otherProps } = this.props;
    return (
      <FormControlLabel
        control={
          <Switch
            checked={checked ? (checked == true) : false}
            onChange={e => {
              onChange(e.target.checked);
              callValidationEvent(e.target.checked)
            }}
            {...otherProps}
          />
        }
        classes={{
          root: classes.Switch
        }}
        label={label}
        className={clsx(className)}
      >
      </FormControlLabel>
    );
  }
}
export default React.memo(withStyles(styles)(FormSwitch));
