import React, { useContext } from 'react';
import modlog from '../assets/images/mod.png';
import { AppContext } from '../AppContext';
//import imgrobot from '../assets/images/404.gif';
const Logout = React.memo(props => {
    const { Dispatcher } = useContext(AppContext);
    return (<>
        <header>
            <a href='https://www.mod.gov.il'><img className='imgLogo img-fluid pr-3 pt-3' src={modlog} /></a>
        </header>
        <div className='loginwrapper'>
            <div className="ModLogin pagenotfound">
                {/* <img src={imgrobot}/> */}
                <h2>יציאה מאיזור אישי</h2>
                {Dispatcher && Dispatcher.PersonalAreaUrl.length > 0 &&
                    <a href={Dispatcher.PersonalAreaUrl}>חזרה לאתר</a>
                }
            </div>
        </div>
    </>
    )
});
export default Logout;