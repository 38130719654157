import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';

import useForm from '../../formsFramework/core/useForm';
import { TextField } from "@material-ui/core";
import api from '../../webApi';
import clsx from "clsx";
import moment from 'moment';
import '../Login/Login.css';
import loginbackground from '../../assets/images/loginbackground.png';
import { require, onlyNumbers, email, phoneNumber, minLength, maxLength, length, passwordvalidator ,rangeLength} from "../../formsFramework/validators";
import BusinessCategory from '../../assets/static-data/BusinessCategory';
import { FormContainer, FormField } from '../../formsFramework';
import { FormTextField, FormDatePicker, FormSelectField, FormPasswordField } from "../../formsFramework/MaterialComponents";
import Divider from '@material-ui/core/Divider';
import { async } from 'react-app-polyfill/node_modules/regenerator-runtime';
import ModModal from '../../components/layout/ModModal/ModModal';
import { AppContext } from '../../AppContext';



const SecStepRegistration = React.memo(props => {
    const location = useLocation();
    const [displayModal, setDisplayModal] = useState(false);
    const { Dispatcher, displaySnackbarHandler, setLoading } = useContext(AppContext);
    const [userKey, setUserKey] = useState(null);
    const [isFirstEntry, setIsFirstEntry] = useState(false);
    const [reEnterPassError, setReEnterPassError] = useState(false);
    const history = useHistory();


    let btnRef = useRef();

    const sleep = async (time) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve()
            }, time)
        })
    }
    useEffect(() => {
        //debugger;
        //location.state = { UserKey: 'hq704767', isFirstEntry: true };
        if (location.state == undefined) {
            window.top.location.href = "/";
            //location.state = { UserKey: 'hq704767', isFirstEntry: true };
        }
        //else
        {
            setUserKey(location.state.UserKey);
            setIsFirstEntry(location.state.isFirstEntry);
        }
        // if (Dispatcher == null)
        //     dispatcherHandler();
    }, [])
    const handleOnSubmit = async (validatioState, form) => {
        btnRef.current.focus();
        btnRef.current.disabled = true;
        if (form.newuserpassword != form.reEnteruserpassword) {
            setReEnterPassError(true);
            return;
        }
        else {
            setReEnterPassError(false);
        }
        if (validatioState) {
            setLoading(true);

            const loginDTO = {
                user: {
                    uid: userKey,
                    businessCategory: form.ModQuestion == undefined ? null : form.ModQuestion + ";" + form.userAnswer,
                    password: form.newuserpassword,
                },
                siteurl: Dispatcher.rootUrl,
                continueToUrl: Dispatcher.PersonalAreaUrl
                //isFirstEntry: isFirstEntry,
                //question: form.ModQuestion,
                //answer: form.userAnswer

            }
            //const res = await api.post(`ModLogin/SignIn`, loginDTO).then(response => {
            const res = await api.post(`ModLogin/UpdateRegistration`, loginDTO).then(response => {
                if (response && response.data && response.data.status) {
                    console.log('UpdateRegistration rseponse seccess ' + response);
                    window.location.href = Dispatcher.PersonalAreaUrl;//Dispatcher.protocol + '://' + Dispatcher.rootUrl + Dispatcher.PersonalAreaUrl;
                }
                else
                    if (!response || (response && response.data && !response.data.status)) {
                        console.log('response error');
                        displaySnackbar("error", "ארעה שגיאה  , אנא פני/ה למוקד התמיכה", 'top', 'center');
                    }
            }).catch((error) => {
                if (error.message.indexOf("302") > 0) {

                    window.location.href = Dispatcher.PersonalAreaUrl;//Dispatcher.protocol + '://' + Dispatcher.rootUrl + Dispatcher.PersonalAreaUrl;
                }
                else {
                    console.log(error);
                    displaySnackbar("error", "בעיה בעת התחברות משתמש, אנא פנה/י למוקד התמיכה", 'top', 'center');
                }
            });;

        }
        else {
            console.log('validation failed');
            displaySnackbar("error", "תבנית סיסמה אינה תקינה", 'top', 'center');
        }
        //await sleep(8000);
        btnRef.current.disabled = false;
        setLoading(false);
    };


    function handleOnClose() {
        // setShowModal(false);
    }

    const displaySnackbar = (variant, message = '', vertical = '', horizontal = '') => {
        //setSnackBarProps({ display: true, variant: variant, message: message, vertical: vertical, horizontal: horizontal })
        displaySnackbarHandler(variant, message, vertical, horizontal);
    }

    const form = () => {
        return <FormContainer onSubmit={handleOnSubmit}
            defaultValues={{}}>
            {
                ({ values, submited, valid, validationState, reValidateField, resetField }) => {

                    return (
                        <>
                            <div className="formContainer">
                                <fieldset>
                                    <legend>שינוי סיסמה ראשונית</legend>
                                    <div className="formRow">
                                        <FormField
                                            type="text"
                                            name="olduserpassword"
                                            label="סיסמה ישנה"
                                            id="Mod-olduserpassword"
                                            Component={FormPasswordField}
                                            variant="standard"
                                            required
                                            validators={[require, rangeLength]}
                                            inputProps={{ maxLength: 12 }}
                                            
                                            validationText="יש להזין סיסמה"
                                            className={clsx("formField")}
                                        />
                                    </div>
                                    <div className="formRow">
                                        {/* <IconButton
                                            aria-label="explan password validation"
                                            type='info'
                                        >
                                            <Tooltip title='יש להזין סיסמה באנגלית באורך 8 תווים, המורכבת מאות גדולה אות קטנה מספר ותו מיוחד'>
                                                <InfoRounded />
                                            </Tooltip>
                                        </IconButton> */}
                                        <FormField
                                            type="text"
                                            name="newuserpassword"
                                            label="סיסמה חדשה"
                                            id="Mod-newuserpassword"
                                            Component={FormPasswordField}
                                            variant="standard"
                                            required
                                            validators={[require, passwordvalidator]}
                                            inputProps={{ maxLength: 12 ,showinfo:true}}
                                           
                                            validationText="יש להזין סיסמה באנגלית באורך 12 תווים, המורכבת מאות גדולה אות קטנה מספר ותו מיוחד"
                                            className={clsx("formField")}
                                        />
                                    </div>
                                    <div className="formRow">
                                        <FormField
                                            type="text"
                                            name="reEnteruserpassword"
                                            label="אימות סיסמה חדשה"
                                            id="Mod-reEnteruserpassword"
                                            Component={FormPasswordField}
                                            variant="standard"
                                            required
                                            validators={[require,]}
                                            inputProps={{ maxLength: 12 }}
                                            
                                            validationText="יש להזין סיסמה"
                                            className={clsx("formField")}
                                        />
                                        {reEnterPassError &&
                                            <span id="reEnterPassError-helper-text">אימות נכשל</span>}
                                    </div>
                                    {isFirstEntry && <>
                                        <div className="formRow">
                                            <FormField
                                                name="ModQuestion"
                                                Component={FormSelectField}
                                                variant="outlined"
                                                label="בחירת שאלת הזדהות"
                                                id="ModQuestion"
                                                validators={isFirstEntry ? [require] : []}
                                                className={clsx("formField",)}
                                                valid={!isFirstEntry}

                                                selectValues={BusinessCategory.BusinessCategory}
                                            />
                                        </div>
                                        <div className="formRow">
                                            <FormField
                                                type="text"
                                                name="userAnswer"
                                                label="תשובה"
                                                id="Answer"
                                                Component={FormTextField}
                                                variant="standard"
                                                valid={!isFirstEntry}
                                                validators={isFirstEntry ? [require] : []}
                                                className={clsx("formField")}
                                            />
                                        </div>
                                    </>
                                    }
                                </fieldset>

                                <div className="submitWrap">
                                    <button ref={btnRef} type="submit" name="submit" className="submitBut smsbtns" >שליחה</button>
                                </div>
                            </div>
                        </>
                    )
                }
            }
        </FormContainer>
    }
    return (<div className='logincontainer firstEntry oneform'>
        {form()}
    </div>

    );
});
export default SecStepRegistration;
