import React from "react";
import { withStyles } from "@material-ui/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker
} from "@material-ui/pickers";
import heLocale from "date-fns/locale/he";
import DateFnsUtils from "@date-io/date-fns";
import NavigateNext from "@material-ui/icons/NavigateNext";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import { isNull } from "util";
const styles = theme => ({
  datePicker: {
    marginTop: "16px"
  }
});
export default React.memo(withStyles(styles)(function FormDatePicker(props) {
  const {
    name,
    validationText,
    onChange,
    classes,
    className,
    value,
    callValidationEvent,
    variant = "date",
    validating,
    ...otherProps
  } = props;
  const handleChange = (date) => {
    onChange(date);
    callValidationEvent(date);
  }
  const selectedVariant =
    variant === "date" ? (
      <KeyboardDatePicker
        invalidDateMessage={validationText}
        value={value === "" ? null : value}
        className={`${className} ${classes.datePicker}`}
        clearable
        DialogProps={{ style: { direction: "rtl" } }}
        onChange={(date, value) => handleChange(date)}
        helperText={validationText}
        //onClose={newValue => props.callValidationEvent(props.value)}
        {...otherProps}
      />
    ) : variant === "time" ? (
      <KeyboardTimePicker
        invalidDateMessage={validationText}
        value={value === "" ? null : value}
        className={`${className} ${classes.datePicker}`}
        clearable
        helperText={validationText}
        DialogProps={{ style: { direction: "rtl" } }}
        onChange={(date, value) => handleChange(date)}
        onClose={newValue => props.callValidationEvent()}
        {...otherProps}
      />
    ) : (
          isNull
        );
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={heLocale}>
      {selectedVariant}
    </MuiPickersUtilsProvider>
  );
}));
