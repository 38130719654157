import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory,Link } from 'react-router-dom';
import useForm from '../../formsFramework/core/useForm';
import { useLocation } from 'react-router-dom';
import api from '../../webApi';
import clsx from "clsx";
import moment from 'moment';
import './Login.css';
import loginbackground from '../../assets/images/loginbackground.png';
import usersignin from '../../assets/images/usersignin.png';
import usersms from '../../assets/images/usersms.png';
import { require, onlyNumbers, email, minLength, maxLength, length } from "../../formsFramework/validators";
import phonePrefixes from '../../assets/static-data/phonePrefixes';
import { FormContainer, FormField } from '../../formsFramework';
import { FormTextField } from "../../formsFramework/MaterialComponents";
import Divider from '@material-ui/core/Divider';
import { async } from 'react-app-polyfill/node_modules/regenerator-runtime';
//import ModSnakeBar from '../layout/ModSnakeBar/ModSnakeBar';
import ModModal from '../layout/ModModal/ModModal';
import { CircularProgress } from "@material-ui/core";
import { OutlinedInput, InputAdornment, FormControl, InputLabel, IconButton, FormLabel } from "@material-ui/core";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Label } from '@material-ui/icons';
import { AppContext } from '../../AppContext';
import LoginHeader from '../layout/Header/LoginHeader';

const getIsMobile = () => window.innerWidth <= 800;
const QuestionandAnswer = React.memo(props => {
    const location = useLocation();
    const { Dispatcher,displaySnackbarHandler,setLoading } = useContext(AppContext);
    const [isMobile, setIsMobile] = useState(getIsMobile());
    //const [snackBarProps, setSnackBarProps] = useState({ display: false, variant: 'info', message: '', vertical: '', horizontal: '' });
    const [displayModal, setDisplayModal] = useState(false);
    const [question, SetQuestion] = useState(location.state.question);
    //const [disableButReg,setDisableButReg] = useState(true);
    //const [loading, setLoading] = useState(false);
    const {
        UserKey,
        Email,
        Userid
    } = location.state.loginDTO;
    const history = useHistory();

    let btnRef = useRef();

    useEffect(() => {       
        console.log('question ' + question)
        const onResize = () => {
            setIsMobile(getIsMobile);
        };
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);
    const displaySnackbar = (variant, message = '', vertical = '', horizontal = '') => {
        //setSnackBarProps({ display: true, variant: variant, message: message, vertical: vertical, horizontal: horizontal })
        displaySnackbarHandler(variant, message, vertical, horizontal);
    }
    const handleOnSubmit = async (validatioState, form) => {
        btnRef.current.focus();
        btnRef.current.disabled = true;
        setLoading(true);
        if (validatioState) {

            const loginDTO = {
                useranswer: form.MODAnswer,
                UserKey: UserKey,
                email: Email,
                employeenumber: Userid,
                siteurl: Dispatcher.rootUrl
            }
            const res = await api.post(`ModLogin/AnswerTheQuestion`, loginDTO).then(response => {
                if (response) {
                    if (response && response.data && !response.data.status) {// errors
                        displaySnackbar("error", "ארעה שגיאה, אנא פני/ה למוקד התמיכה", 'top', 'center');
                    }
                    else
                        if (response && response.data && response.data.status) {
                            //go back to login form
                            window.top.location.href =Dispatcher.PersonalAreaUrl;//Dispatcher.protocol + '://' + Dispatcher.rootUrl + Dispatcher.PersonalAreaUrl;;
                        }
                }
                else {
                    displaySnackbar("error", "משתמש לא קיים במערכת, אנא פני/ה למוקד התמיכה", 'top', 'center');
                }
            }).catch((error) => {
                console.log(error);
                displaySnackbar("error", "בעיה בעת התחברות משתמש, אנא פנה/י למוקד התמיכה", 'top', 'center');
            });;

        }

        btnRef.current.disabled = false;
        setLoading(false);
    };

    const form = () => {
        return <FormContainer onSubmit={handleOnSubmit}
            defaultValues={{}}>
            {
                ({ values, submited, valid, validationState, reValidateField, resetField }) => {
                    return (
                        <>
                            {!isMobile &&
                                <>
                                    <div className='iconSignin iconSigninsms'>
                                        <img src={usersms} />
                                    </div>
                                </>
                            }
                            <div className="formContainer">
                                <fieldset>
                                    <legend>שאלת אימות</legend>
                                    <div className="formRow pt-5">
                                        <FormLabel className={clsx("formField formlabel")} Component='legend'>{question}</FormLabel>
                                        <FormField
                                            type="text"
                                            name="MODAnswer"
                                            label="תשובה"
                                            id="Mod-Answer"
                                            Component={FormTextField}
                                            variant="outlined"
                                            required
                                            className={clsx("formField")}
                                        />

                                    </div>
                                </fieldset>
                                <div className="submitWrap">
                                    <button ref={btnRef} type="submit" name="submit" className="submitBut" >שלח קוד לנייד שלי</button>
                                </div>
                            </div>
                        </>
                    )
                }
            }
        </FormContainer>
    }

    return (<div className='logincontainer oneform'>
                    {form()}
                </div>
    )
});
export default QuestionandAnswer;