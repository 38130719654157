import React ,{useEffect,useState} from 'react';
import PropTypes from'prop-types';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { Snackbar,SnackbarContent,MuiAlert, IconButton} from '@material-ui/core';
import   './ModSnakeBar.css'

const variants ={
    success:{icon:CheckCircleIcon,class:'successSnack',  message:'הפעולה התבצעה בהצלחה!'},
    error:{icon:ErrorIcon,class:'errorSnack', message:'אירעה שגיאה באתר, אנא פנה לשירות הלקוחות !'},
    info:{icon:InfoIcon,class:'infoSnack', message:'הודעת מערכת'},
    warning:{icon:WarningIcon,class:'warningSnack', message:'הודעת מערכת'}
}

export default function ModSnakeBar(props){
    const {display, variant, message,vertical,horizontal} = props;
    const [open, setOpen] = useState(false);

   useEffect(()=> {
       if(props.display === true){
           setOpen(true);
         } 
    },[props])

    const handleOpen = ()=>{
        setOpen(true);
    }
    const handleClose = (event, reason)=>{
        if(reason === 'clickaway'){
            return;
        }
        window.scrollTo(0,0);
        setOpen(false);
    }

    const Icon = variants[variant].icon;
    return(
        <div className='ModSnackbar'>
            <Snackbar
                anchorOrigin={{
                    vertical: vertical !== '' ? vertical:'bottom' ,
                    horizontal: horizontal !== '' ? horizontal:"right"
                }}
                open={open}
                autoHideDuration={7000}
                onClose={handleClose}
               aria-alive="polite"
                >
                <SnackbarContent
                    className={variants ? variants[variant].class : ''}
                    message={
                        <span className='message'>
                            <Icon className='iconVariant' />
                            {message===''? variants[variant].message:message}
                        </span>
                    }  
                    action={[
                        <IconButton key="close" color="inherit" onClick={handleClose}>
                            <CloseIcon className='icon'/>
                        </IconButton>
                    ]}              
                ></SnackbarContent>                        
            </Snackbar> 

        </div>
    )
}
