import React,{ Fragment } from "react";
import { TextField, InputAdornment,FormHelperText } from "@material-ui/core";
import LoadingIcon from "@material-ui/icons/Cached";
const FormTextField = React.memo(props =>{

  
  const handleChange=(value)=>
  {
    //debugger;
    if(props.hasOwnProperty('checkonlynumbers') && props.checkonlynumbers=='true')
    {
      const regex =/^[0-9\b]+$/;
      if(value==="" || regex.test(value))
      {return onChange(value);}
    }
    else{
      return onChange(value);
    }
  }
    const {
      name,
      validationText,
      onChange,
      callValidationEvent,
      validating,
      inputProps,
      helperText,
      ...otherProps
    } = props;
    let containMinLength = false;
  
    let mixedHelperText = (validationText ) ? validationText : helperText ? helperText : '';
    return (
      <Fragment>
        <TextField
        helperText={mixedHelperText}
        FormHelperTextProps={{
          id:name+"-helper-text"
        }}
         onChange={e => handleChange(e.target.value)}
       
        inputProps={
         {
          onBlur: e => callValidationEvent(e.target.value),
          'aria-invalid':mixedHelperText ? true : false,
          'aria-describedby':name+"-helper-text",
          endadornment: (
            <InputAdornment position="end">
              {validating && <LoadingIcon className="rotate" />}
            </InputAdornment>
          ),
          ...inputProps
        }}
        {...otherProps}
        margin="normal"
      />            
      </Fragment>

      
    );
  
});
// class FormTextField extends React.Component {

//   render() {

//     const {
//       name,
//       validationText,
//       onChange,
//       callValidationEvent,
//       validating,
//       inputProps,
//       helperText,
//       ...otherProps
//     } = this.props;
//     let containMinLength = false;
  
//     let mixedHelperText = (validationText ) ? validationText : helperText ? helperText : '';
//     return (
//       <Fragment>
//         <TextField
//         helperText={mixedHelperText}
//         FormHelperTextProps={{
//           id:name+"-helper-text"
//         }}
//         onChange={e => onChange(e.target.value)}
       
//         inputProps={
//          {
//           onBlur: e => callValidationEvent(e.target.value),
//           'aria-invalid':mixedHelperText ? true : false,
//           'aria-describedby':name+"-helper-text",
//           endadornment: (
//             <InputAdornment position="end">
//               {validating && <LoadingIcon className="rotate" />}
//             </InputAdornment>
//           ),
//           ...inputProps
//         }}
//         {...otherProps}
//         margin="normal"
//       />            
//       </Fragment>

      
//     );
//   }
// }
export default React.memo(FormTextField);
