import React from "react";
import clsx from "clsx";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  Chip
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
const styles = theme => ({
  selectContainer: {
    textAlign: "left"
  },
  chipsContainer: {
    marginTop: "10px",
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    marginRight: "10px",
    marginBottom: "10px"
  }
});
const ITEM_HEIGHT = 88;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const FormMultipleSelectField = props => {
  const {
    name,
    validationText,
    callValidationEvent,
    value,
    onChange,
    required,
    placeholder,
    label,
    selectValues,
    customFieldNames,
    classes,
    multiple,
    validating,
    chipProperties,
    ...otherProps
  } = props;
  
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);
  let selectedObjects = [];
  const handleDelete = chipToDelete => () => {
    let newValues = [...value].filter(val => val !== chipToDelete[fieldNames.id]);
    onChange(newValues);
    callValidationEvent(newValues)
  };
  let fieldNames = customFieldNames;
  if(!customFieldNames){
    fieldNames = {id:'id',title:'title'}
  }
  return (
    <FormControl required margin="normal" {...otherProps}>
      <InputLabel ref={inputLabel}>{label}</InputLabel>
      <Select
        multiple
        className={classes.selectContainer}
        value={value || []}
        onChange={e => onChange(e.target.value)}
        renderValue={selected => `נבחרו ${selected.length} קטגוריות`}
        MenuProps={MenuProps}
        onClose={e => callValidationEvent(value)}
        input={
          <OutlinedInput
            labelWidth={labelWidth}
            name={name}
          //onBlur={e => callValidationEvent(e.target.value)}
          />
        }
      >
        <MenuItem value="">
          <em>בחר</em>
        </MenuItem>
        {selectValues &&
          selectValues.map(selectValue => {
            if (value.indexOf(selectValue[fieldNames.id]) > -1) {
              selectedObjects.push(selectValue);
            }
            return (
              <MenuItem key={selectValue[fieldNames.id]} value={selectValue[fieldNames.id]}>
                <Checkbox checked={value.indexOf(selectValue[fieldNames.id]) > -1} />
                <ListItemText primary={selectValue[fieldNames.title]} />
              </MenuItem>
            );
          })}
      </Select>
      <div className={classes.chipsContainer}>
        {selectedObjects.map((selectedObject, index) => (
          <Chip
            variant="outlined"
            key={index}
            label={selectedObject[fieldNames.title]}
            onDelete={handleDelete(selectedObject)}
            className={clsx(classes.chip)}
            {...chipProperties}
          />
        ))}
      </div>
      {
        props.error ?(<FormHelperText>{validationText}</FormHelperText>):null
      }
      
    </FormControl>
  );
};
export default React.memo(withStyles(styles)(FormMultipleSelectField));
