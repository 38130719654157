export default {
  mobilePrefix: [
    {
      "id": "050",
      "title": "050"
    },
    {
      "id": "051",
      "title": "051"
    },
    {
      "id": "052",
      "title": "052"
    },
    {
      "id": "053",
      "title": "053"
    },
    {
      "id": "054",
      "title": "054"
    },
    {
      "id": "055",
      "title": "055"
    },
    {
      "id": "056",
      "title": "056"
    },
    {
      "id": "057",
      "title": "057"
    },
    {
      "id": "058",
      "title": "058"
    },
    {
      "id": "072",
      "title": "072"
    },
    {
      "id": "073",
      "title": "073"
    },
    {
      "id": "077",
      "title": "077"
    }
  ],
  phonePrefix: [
    {
      "id": "02",
      "title": "02"
    },
    {
      "id": "03",
      "title": "03"
    },
    {
      "id": "04",
      "title": "04"
    },
    {
      "id": "08",
      "title": "08"
    },
    {
      "id": "09",
      "title": "09"
    },
    {
      "id": "050",
      "title": "050"
    },
    {
      "id": "051",
      "title": "051"
    },
    {
      "id": "052",
      "title": "052"
    },
    {
      "id": "053",
      "title": "053"
    },
    {
      "id": "054",
      "title": "054"
    },
    {
      "id": "055",
      "title": "055"
    },
    {
      "id": "056",
      "title": "056"
    },
    {
      "id": "057",
      "title": "057"
    },
    {
      "id": "058",
      "title": "058"
    },
    {
      "id": "072",
      "title": "072"
    },
    {
      "id": "073",
      "title": "073"
    },
    {
      "id": "077",
      "title": "077"
    }
  ]
}
