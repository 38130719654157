import React from "react";
import clsx from "clsx";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
const styles = (theme) => ({
  checkbox: {
    justifyContent: "space-between",
    direction: "initial",
    marginLeft: "0px",
    marginRight: "0px"
  }
})
class FormCheckbox extends React.Component {
  render() {
    const { name, validationText, validating, callValidationEvent, onChange, value, label, error, className, classes, ...otherProps } = this.props;
    return (
      <>
      <FormControlLabel
        control={
          <Checkbox
            checked={value ? (value == true) : false}
            onChange={e => {
              onChange(e.target.checked);
              callValidationEvent(e.target.checked);
            }}
            {...otherProps}
          />
        }
        classes={{
          root: classes.checkbox
        }}
        label={label}
        className={clsx(className)}
      >
      </FormControlLabel>
      </>
    );
  }
}
export default React.memo(withStyles(styles)(FormCheckbox));
