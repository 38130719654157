import React, { Fragment } from "react";
import { OutlinedInput, InputAdornment, FormControl, InputLabel, IconButton, FormHelperText, Tooltip } from "@material-ui/core";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InfoRounded from '@material-ui/icons/InfoRounded';
import clsx from "clsx";

const FormPasswordField = React.memo(props => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const {
    id,
    name,
    label,
    validationText,
    onChange,
    callValidationEvent,
    validating,
    inputProps,
    helperText,
    className,
    variant,
    FormHelperTextProps,
    ...otherProps
  } = props;
  let containMinLength = false;

  let mixedHelperText = (validationText) ? validationText : helperText ? helperText : '';

  return (
    <Fragment>
      <FormControl className={clsx(className)} sx={{ m: 1 }} variant="standard"  margin="normal">
        <InputLabel htmlFor={id}>{label}
          {/* {inputProps.showinfo && 
            <IconButton
              aria-label="explain password validation"
              type='info'
            >
              <Tooltip title='יש להזין סיסמה באנגלית באורך 12 תווים, המורכבת מאות גדולה אות קטנה מספר ותו מיוחד'>
                <InfoRounded fontSize='small' />
              </Tooltip>
            </IconButton>
          } */}
        </InputLabel>
        <OutlinedInput
          type={showPassword ? 'text' : 'password'}
          id={id}
          name={name}
          // aria-describedby={name+"-helper-text"} 
          aria-invalid={mixedHelperText ? true : false}
          onChange={e => onChange(e.target.value)}
         
          inputProps={
            {
              onBlur: e => callValidationEvent(e.target.value),
              'aria-invalid': mixedHelperText ? true : false,
              'aria-describedby': name + "-helper-text",
              
              ...inputProps
            }}
           startAdornment={
            inputProps.showinfo && 
            <InputAdornment position="start">
              <IconButton
                aria-label="explan password validation"
                type='info'
              >
                <Tooltip title='יש להזין סיסמה באנגלית באורך 12 תווים, המורכבת מאות גדולה אות קטנה מספר ותו מיוחד'>
                  <InfoRounded fontSize='small'/>
                </Tooltip>
              </IconButton>
            </InputAdornment>
            
           }
          endAdornment=
          {

            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}

              >
                {showPassword ? <VisibilityOff /> : <Visibility fontSize='large' />}
              </IconButton>
            </InputAdornment>
          }
        //  {...otherProps}
      
        />
        <FormHelperText {...FormHelperTextProps} id={name + "-helper-text"}>{mixedHelperText}</FormHelperText>
      </FormControl>
    </Fragment>


  );

});
export default FormPasswordField;
