import React, { Fragment, useState, useRef,useEffect,useContext } from "react";
import clsx from "clsx";
import { InputBase,ClickAwayListener, IconButton,FormHelperText} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { width } from "@material-ui/system";
//import editIcon from '../../assets/images/SVG/edit.svg';
import CloseIcon from "@material-ui/icons/Close";
import { isDirective } from "@babel/types";
import LoadingIcon from "@material-ui/icons/Cached";
import FormContext from "./../core/formContext";
const styles = (theme) => ({
  FormInputBase: {
    // maxWidth:'90px',
    fontSize:16,
    color:'#344058',
    width:'300px'
  },
  IconButton:
  {
    marginRight:'50px'
    //border:'1px solid red'
  },
})
const FormInputBase  = props => {
  
  //render() {
    const { name, validationText, callValidationEvent, onChange,onBlur, value, label, error, className, classes,
      checkValidPage,editable,displayIcon,disabled, validForm,inputProps, helperText, ...otherProps } = props;
      const {
        getValidationState
      } = useContext(FormContext);
   const [isValid, setIsValid] = useState(validForm);
   const [isDisabled,setDisabled] = useState(disabled);
   const inputRef = useRef();
   let mixedHelperText = validationText ? validationText : helperText ? helperText : '';
  //  const onItemClickHandler = (event) => {
  // }

  useEffect(()=>{
    setDisabled(disabled)
  },[disabled]);
  
  const onClickAwayHandler =(e)=>{
      setDisabled(true);
  }
  const onEditClickHandler = (event,name)=>{
    event.preventDefault();
    setDisabled(false);
    inputRef.current.focus();
  }

    return (
   <Fragment>
      <ClickAwayListener onClickAway={e=>onClickAwayHandler(e)}>
          <InputBase
          classes={{
            root: classes.FormInputBase
          }}
          className={clsx(className)}
          aria-describedby={name+"-helper-text"}
          value={value}
          helperText={mixedHelperText}
          onChange={e => onChange(e.target.value)}

          inputRef={inputRef}
          disabled={isDisabled}
          inputProps={{
            onBlur: e => {callValidationEvent(e.target.value);
              },
           ...inputProps
         }}
          />
            </ClickAwayListener>
                 {
             !error  ? (<FormHelperText id={name+"-helper-text"} className="Mui-error">{validationText}</FormHelperText>):null
      }
    </Fragment>
    )
}
//}
export default React.memo(withStyles(styles)(FormInputBase));
