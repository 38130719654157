import axios from 'axios';
//import consts from './common/consts';
//import configuration from './configuration';
//console.log(`${process.env.REACT_APP_API_BASE_URL}`);
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}api/`
});

api.interceptors.request.use(function(config){
  // debugger;
  // const islogdin = sessionStorage.getItem('continueToLogin');
  // if(islogdin != null && islogdin=="true")
  // {
  //   config.headers["employeenumber"] = "100000001"; 
  //   config.headers["am-eai-user-id"] = "hq704767";
  //   config.headers["am-eai-redir-url"]="online";
  //   config.headers["eai-orig-user"]= "hq704767";
  //   config.headers["am-eai-xattrs"]="eai-orig-user,employeenumber";
  // }
  return config
})
api.initApiErrorHandling = (displaySnackbar,navigation)=>{
  api.interceptors.response.use(
    function (response) { return response; },
    (error)=> handleApiErrors(displaySnackbar,navigation,error)
  )
}
const handleApiErrors = (displaySnackbar,navigation,error)=>{
  if (error.response) {
    if(error.response.status === 401){
      //displaySnackbar(consts.SnakebarTypes.ERROR,'שגיאת אבטחה, אינך ראשי לבצע פעולה זו !');
      navigation.push('/Registration/');//TODO:Check
      
    }
    else{
      //displaySnackbar(consts.SnakebarTypes.ERROR);
    }
    return error.response;
  }
}

window.URL.createObjectURL = function(){};
export default api;

