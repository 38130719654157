import React, {Fragment} from 'react';
import { useHistory, Link } from 'react-router-dom';

const UserExists = React.memo(props =>{
const smsLink = window.SMSPageUrl;
const forgatUserName=window.ForgotUserUrl;
const recoveryPassword =window.RecoveryPasswordUrl
return (
    <Fragment>
        <div>שלום רב,</div>
        <div>נמצא כי קיים משתמש רשום לאזור האישי.</div>
        <div>
             לצורך כניסה מהירה באמצעות קוד ב SMS לחץ
             < Link className='registerLink' to='/'>כאן</Link>
       </div>
       <br/>
       {/* <div>
             שכחת שם משתמש?
             <br/>
              ניתן לשחזר אותו
            <a href={forgatUserName}> כאן</a>
       </div>
       <br/> */}
       <div>
            שכחת סיסמה? <br/>
             ניתן לשחזר אותה
            < Link className='registerLink' to='/ForgotPassword'>כאן</Link>
       </div> <br/>
        <div  className="telSection">עדיין צריך עזרה? <br/>
        ניתן לפנות אל מרכז השירות הטלפוני בין השעות 8:00-16:00 בטלפון:
            <a href="tel:*5266">5266*</a>
            <span >5266*</span>
        </div>
       
   </Fragment>
);
});
export default UserExists;