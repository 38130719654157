import React, { Fragment } from "react";
import FormContext from "./formContext";
class FormFieldArray extends React.Component {
  state = {
    currentValue: [],
    key: 3
  };
  addItem = (getFieldValue, name, setFieldValue, defaultValue = {}) => {
    const currentFieldValue = getFieldValue({ name });
    const fieldNewState = currentFieldValue ? [...currentFieldValue, defaultValue] : [defaultValue];
    setFieldValue(name, fieldNewState);
  };
  removeItem = (
    state,
    name,
    index,
    setFieldValue,
    setValidationState,
    setTouchedState,
    getFieldValue,
    getValidationState,
    getTouchedState
  ) => {
    const currentArrayFieldValue = getFieldValue({ name });
    const validationState = getValidationState();
    const touchedState = getTouchedState();
    const fieldNewState = currentArrayFieldValue.filter((item, i) => i != index);
    //handle validation state
    const newValidationState = Object.keys(validationState).reduce(
      (accu, key) => {
        if (!key.includes(`${name}[${index}]`)) {
          // handle index of the array
          // rearange the index  
          // check if the key is an element of the array
          const arrayNamePosition = key.indexOf(`${name}[`)
          if (arrayNamePosition !== -1) {
            // element of the array in the validation state
            const indexInTheArray = parseInt(key[arrayNamePosition + name.length + 1])
            if (indexInTheArray > index) {
              // reduse the index of the element
              accu[key.replace(`${name}[${indexInTheArray}`, `${name}[${indexInTheArray - 1}`)] = validationState[key];
              delete validationState[key];
            }
            else {
              accu[key] = validationState[key];
            }
          }
          else {
            accu[key] = validationState[key];
          }
        }
        return accu;
      },
      {}
    );
    //handle touch
    const newTouchedState = Object.keys(touchedState).reduce(
      (accu, key) => {
        if (!key.includes(`${name}[${index}]`)) {
          accu[key] = touchedState[key];
        }
        return accu;
      },
      {}
    );
    //const validationState = state.validationState
    setFieldValue(name, fieldNewState);
    setValidationState(newValidationState);
    setTouchedState(newTouchedState);
  };
  async componentDidMount() {
    // Initilize the array field- 
    // 1. We want it to be always valid. 
    // 2. For this stage the array field does not support validation for the entire array.
    //    each field has his own validation
    let { name } = this.props;
    const {
      validateField
    } = this.context;
    const validationResult = await validateField([], "", name);
  }
  render() {
    const { name, initialValue, ...otherProps } = this.props;
    const {
      state,
      setFieldValue,
      getFieldValue,
      setValidationState,
      setTouchedState,
      getValidationState,
      getTouchedState
    } = this.context;
    const arrayValue = getFieldValue({ name }) || []
    return (
      <Fragment>
        {this.props.children({
          items: arrayValue,
          addItem: (defaultValue) => this.addItem(getFieldValue, name, setFieldValue, defaultValue),
          removeItem: index =>
            this.removeItem(
              state,
              name,
              index,
              setFieldValue,
              setValidationState,
              setTouchedState,
              getFieldValue,
              getValidationState,
              getTouchedState
            ),
          name: name
        })}
      </Fragment>
    );
  }
}
FormFieldArray.contextType = FormContext;
export default FormFieldArray;
/**
  accepted props :
      name - the name of the form field
      initialValue- array with objects, each object contains the fields of the array row
      children - single function
                sends:
                    items - the items of the array
                    addItem -  adds new empty item to the array
                    removeitem - gets item index and remove it
                return:
                    JSX - the user needs to return jsx from the function
 */
