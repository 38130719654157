import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import useForm from '../../formsFramework/core/useForm';
// import { Link, TextField } from "@material-ui/core";
import api from '../../webApi';
import clsx from "clsx";
import moment from 'moment';
import './Login.css';
import loginbackground from '../../assets/images/loginbackground.png';
import usersignin from '../../assets/images/usersignin.png';
import usersms from '../../assets/images/usersms.png';
import { require, onlyNumbers, email, phoneNumber, minLength, maxLength, length, rangeLength } from "../../formsFramework/validators";
import phonePrefixes from '../../assets/static-data/phonePrefixes';
import { FormContainer, FormField } from '../../formsFramework';
import { FormTextField, FormPasswordField, FormCheckbox, FormSwitch } from "../../formsFramework/MaterialComponents";
import Divider from '@material-ui/core/Divider';
import { async } from 'react-app-polyfill/node_modules/regenerator-runtime';
//import ModSnakeBar from '../layout/ModSnakeBar/ModSnakeBar';
import ModModal from '../../components/layout/ModModal/ModModal';
import { CircularProgress } from "@material-ui/core";
import { OutlinedInput, InputAdornment, FormControl, InputLabel, IconButton } from "@material-ui/core";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { AppContext } from '../../AppContext';
import LoginHeader from '../layout/Header/LoginHeader';

const getIsMobile = () => window.innerWidth <= 800;
const Login = React.memo(props => {
    const [isMobile, setIsMobile] = useState(getIsMobile());
    const [isSMSview, setIsSMSview] = useState(true);
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    //const [snackBarProps, setSnackBarProps] = useState({ display: false, variant: 'info', message: '', vertical: '', horizontal: '' });
    const [displayModal, setDisplayModal] = useState(false);
    //const [disableButReg,setDisableButReg] = useState(true);
    //const [loading, setLoading] = useState(false);
    const { Dispatcher, displaySnackbarHandler, setLoading } = useContext(AppContext);
    const history = useHistory();

    let btnRef = useRef();
    let btnSMSRef = useRef();
    const sleep = async (time) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve()
            }, time)
        })
    }
    useEffect(() => {
        //displaySnackbar('info', 'בדיקת הודעה!!', 'top', 'center');
        sessionStorage.setItem('continueToLogin', false);
        const onResize = () => {
            setIsMobile(getIsMobile);
        };
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);
    const displaySnackbar = (variant, message = '', vertical = '', horizontal = '') => {
        displaySnackbarHandler(variant, message, vertical, horizontal);
        // setSnackBarProps({ display: true, variant: variant, message: message, vertical: vertical, horizontal: horizontal });
        setLoading(false);
    }
    const handleOnSubmit = async (validatioState, form) => {

        try {

            btnRef.current.focus();
            btnRef.current.disabled = true;

            if (validatioState) {
                setLoading(true);
                const loginDTO = {
                    UserKey: form.userKey,
                    UserPassword: form.userpassword,
                    Url: Dispatcher.PersonalAreaUrl,
                    RootUrl: Dispatcher.rootUrl
                }
                //const res = await api.post(`ModLogin/SignIn`, loginDTO).then(response => {
                const res = await api.post(`ModLogin/SignInAuth`, loginDTO).then(response => {
                    if (response) {
                        debugger;
                        /**rsponse: 
                         *  shortphone
                         *  responseId(smsUID)
                        */
                        if (response && response.data && !response.data.status &&
                            (response.data.ErrorType == 13)//First Entry
                            || (response.data.Data.errorcode == 5) //password expired
                            || (response.data.ErrorType == 10))//after password resete
                        {
                            history.push({
                                pathname: "/SecStepRegistration",
                                state: { UserKey: loginDTO.UserKey, isFirstEntry: response.data.ErrorType == 13 }
                            });
                        }
                        else
                            if (response && response.data && !response.data.status && response.data.Data.errorcode == 1) {
                                displaySnackbar("error", "משתמש לא קיים במערכת, אנא פני/ה למוקד התמיכה", 'top', 'center');

                            }
                            else
                                if (response && response.data && !response.data.status && response.data.Data.errorcode == 2)//wrong password
                                {
                                    displaySnackbar("error", "ארעה שגיאה , אנא נסי/ה שנית", 'top', 'center');
                                    //TODO:clearFields
                                } else
                                    if (response && response.data && !response.data.status//All others errors
                                        /* &&
                                        (response.data.Data.errorcode == 3 ||//"account disabled"
                                            response.data.Data.errorcode == 4 ||//account locked
                                            response.data.Data.errorcode == 6)//"user not in the group"
                                            */
                                    ) {
                                        displaySnackbar("error", "ארעה שגיאה, אנא פני/ה למוקד התמיכה", 'top', 'center');
                                    }
                                    else
                                        if (response && response.data && response.data.status) {
                                            let shortphone = response.data.Data.shortPhone;
                                            let message = 'נשלח קוד סמס למספר XXX-XXX' + shortphone;
                                            displaySnackbar('info', message, 'top', 'center');
                                            //dont arived here
                                            history.push({
                                                pathname: "/SMSFromLogin",
                                                state: { Id: response.data.Data.smsRowId }
                                            });
                                        }
                                        else {
                                            displaySnackbar("error", "ארעה שגיאה, אנא פני/ה למוקד התמיכה", 'top', 'center');
                                        }
                    }
                    else {
                        displaySnackbar("error", "משתמש לא קיים במערכת, אנא פני/ה למוקד התמיכה", 'top', 'center');
                    }
                }).catch((error) => {
                    console.log(error);
                    displaySnackbar("error", "בעיה בעת התחברות משתמש, אנא פנה/י למוקד התמיכה", 'top', 'center');
                }


                );;

            }
            //await sleep(8000);
            if (btnRef.current)
                btnRef.current.disabled = false;
            setLoading(false);
        }
        catch (error) {

            console.log(error);
            if (btnRef.current)
                btnRef.current.disabled = false;
            setLoading(false);
            displaySnackbar("error", "בעיה בעת התחברות משתמש, אנא פנה/י למוקד התמיכה", 'top', 'center');

        }
    };
    // before sms step:
    //const handleOnSubmit = async (validatioState, form) => {

    //     try {

    //         btnRef.current.focus();
    //         btnRef.current.disabled = true;

    //         if (validatioState) {
    //             setLoading(true);
    //             const loginDTO = {
    //                 UserKey: form.userKey,
    //                 UserPassword: form.userpassword,
    //                 Url: Dispatcher.PersonalAreaUrl,
    //                 RootUrl: Dispatcher.rootUrl
    //             }
    //             //const res = await api.post(`ModLogin/SignIn`, loginDTO).then(response => {
    //             const res = await api.post(`ModLogin/SignInAuth`, loginDTO).then(response => {
    //                 if (response) {

    //                     if (response && response.data && !response.data.status &&
    //                         (response.data.ErrorType == 13)//First Entry
    //                         || (response.data.Data.errorcode == 5) //password expired
    //                         || (response.data.ErrorType == 10))//after password resete
    //                     {
    //                         history.push({
    //                             pathname: "/SecStepRegistration",
    //                             state: { UserKey: loginDTO.UserKey, isFirstEntry: response.data.ErrorType == 13 }
    //                         });
    //                     }
    //                     else
    //                         if (response && response.data && !response.data.status && response.data.Data.errorcode == 1) {
    //                             displaySnackbar("error", "משתמש לא קיים במערכת, אנא פני/ה למוקד התמיכה", 'top', 'center');

    //                         }
    //                         else
    //                             if (response && response.data && !response.data.status && response.data.Data.errorcode == 2)//wrong password
    //                             {
    //                                 displaySnackbar("error", "ארעה שגיאה , אנא נסי/ה שנית", 'top', 'center');
    //                                 //TODO:clearFields
    //                             } else
    //                                 if (response && response.data && !response.data.status//All others errors
    //                                     /* &&
    //                                     (response.data.Data.errorcode == 3 ||//"account disabled"
    //                                         response.data.Data.errorcode == 4 ||//account locked
    //                                         response.data.Data.errorcode == 6)//"user not in the group"
    //                                         */
    //                                 ) {
    //                                     displaySnackbar("error", "ארעה שגיאה, אנא פני/ה למוקד התמיכה", 'top', 'center');
    //                                 }
    //                                 else
    //                                     if (response && response.data && response.data.status) {
    //                                         //displaySnackbar('info', 'משתמש התחבר!!', 'top', 'center');
    //                                         //dont arived here
    //                                         window.location.href = Dispatcher.PersonalAreaUrl;//Dispatcher.protocol + '://' + Dispatcher.rootUrl + Dispatcher.PersonalAreaUrl;//"https://logintest.mod.gov.il/onlineservice/lobby.html";
    //                                     }
    //                                     else{
    //                                         displaySnackbar("error", "ארעה שגיאה, אנא פני/ה למוקד התמיכה", 'top', 'center');
    //                                     }
    //                 }
    //                 else {
    //                     displaySnackbar("error", "משתמש לא קיים במערכת, אנא פני/ה למוקד התמיכה", 'top', 'center');
    //                 }
    //             }).catch((error) => {
    //                 if (error.message.indexOf("302") > 0) {
    //                     window.location.href = Dispatcher.PersonalAreaUrl;//Dispatcher.PersonalAreaUrl;//Dispatcher.protocol + '://' + Dispatcher.rootUrl + Dispatcher.PersonalAreaUrl;//"https://logintest.mod.gov.il/onlineservice/lobby.html";
    //                 }
    //                 else {
    //                     console.log(error);
    //                     displaySnackbar("error", "בעיה בעת התחברות משתמש, אנא פנה/י למוקד התמיכה", 'top', 'center');
    //                 }


    //             });;

    //         }
    //         //await sleep(8000);
    //         if (btnRef.current)
    //             btnRef.current.disabled = false;
    //         setLoading(false);
    //     }
    //     catch (error) {

    //         console.log(error);
    //         if (btnRef.current)
    //             btnRef.current.disabled = false;
    //         setLoading(false);
    //         displaySnackbar("error", "בעיה בעת התחברות משתמש, אנא פנה/י למוקד התמיכה", 'top', 'center');

    //     }
    // };
    const handleOnSMSSubmit = async (validatioState, form) => {
        /****CHANGED: countinu even if didnt finish registration */
        //console.log('hit submit, validation state=' + validatioState);
        //console.log('form.PhoneNumber=' + form.PhoneNumber);
        btnSMSRef.current.focus();
        btnSMSRef.current.disabled = true;

        if (validatioState) {
            setLoading(true);
            const loginDTO = {
                IsVoiceMsg: false,
                MODsmsID: form.MODsmsID,
                PhoneNumber: form.PhoneNumber,
                RootUrl: Dispatcher.rootUrl
            }
            const res = await api.post(`ModLogin/SignInBySMS`, loginDTO).then(response => {
                if (response) {
                    if (!response.data.status && response.data.ErrorType == 12) {
                        history.push({
                            pathname: "./sms",
                            state: { loginDTO: loginDTO }
                        });
                    }
                    else
                        if (!response.data.status) {
                            //ADD if errorType==BusinessCategoryEmpty
                            if (response.data.ErrorType == 12) {
                                //displaySnackbar('error', 'יש להשלים תהליך הרשמה! נא להזין שם משתמש וסיסמה', 'top', 'center');
                                history.push({
                                    pathname: "./sms",
                                    state: { loginDTO: loginDTO }
                                });
                            }
                            else
                                displaySnackbar('error', 'ארעה שגיאה, אנא פנה/י למוקד התמיכה', 'top', 'center');
                        }
                        //window.location.href='/SMS';
                        else
                            history.push({
                                pathname: "/sms",
                                state: { loginDTO: loginDTO }
                            });

                }
                else {
                    displaySnackbar("error", "בעיה בעת התחברות משתמש, אנא פנה/י למוקד התמיכה", 'top', 'center');
                }

            }).catch((error) => {
                console.log(error);
                displaySnackbar("error", "בעיה בעת התחברות משתמש, אנא פנה/י למוקד התמיכה", 'top', 'center');
                if (btnSMSRef.current)
                    btnSMSRef.current.disabled = true;
            });;

        }
        //await sleep(8000);
        if (btnSMSRef.current)
            btnSMSRef.current.disabled = false;
        setLoading(false);
    };
    const handleChange = (value) => {
        const regex = /^[0-9\b]+$/;
        if (value === "" || regex.test(value)) { return value; }
    }
    const handleForgot = () => {
        history.push({
            pathname: "/ForgotPassword",
        });
    }
    const formwithpassword = () => {
        return <FormContainer onSubmit={handleOnSubmit} defaultValues={{}}>
            {
                ({ values, submited, valid, validationState, reValidateField, resetField }) => {
                    return (
                        <>
                            {!isMobile &&

                                <div className='iconSignin iconSigninnoraml'>
                                    <img src={usersignin} />
                                </div>
                            }
                            <div className="formContainer">
                                <fieldset>
                                    <legend>עם הסיסמה שלך</legend>
                                    <div className="formRow">
                                        <FormField
                                            type="text"
                                            name="userKey"
                                            label="קוד משתמש"
                                            id="Mod-userKey"
                                            onChange=''
                                            Component={FormTextField}
                                            variant="standard"
                                            required
                                            validators={[require, length]}
                                            inputProps={{ maxLength: 8, autoComplete: 'on' }}
                                            minLength={8}
                                            className={clsx("formField")}
                                        />
                                        <FormField
                                            type="text"
                                            name="userpassword"
                                            label="סיסמה"
                                            id="Mod-userpassword"
                                            Component={FormPasswordField}
                                            variant="standard"
                                            required
                                            validators={[require, rangeLength]}
                                            inputProps={{ minLength: 8, maxLength: 12, autoComplete: 'new-password' }}
                                            //minLength={8}
                                            validationText="יש להזין סיסמה"
                                            className={clsx("formField")}
                                        />
                                        {/* <a className='forgotpasswordlink' href='' onClick={handleForgot}>שכחתם סיסמה? לחצו כאן</a> */}

                                    </div>
                                </fieldset>

                                <div className="submitWrap">
                                    <button ref={btnRef} type="submit" name="submit" className="submitBut" >התחברות</button>

                                    <div>
                                        <Link className='registerLink' to='/ForgotPassword'>שכחתם סיסמה? לחצו כאן</Link>
                                        {Dispatcher && Dispatcher.goToRegistration.length > 0 &&
                                            <a name="goToRegistration" className="registerLink " href={Dispatcher.goToRegistration}>{Dispatcher.RegistrationText}</a>
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
            }
        </FormContainer>
    }
    const formwithsms = () => {
        return <FormContainer onSubmit={handleOnSMSSubmit}
            defaultValues={{}}>
            {
                ({ values, submited, valid, validationState, reValidateField, resetField }) => {
                    return (
                        <>
                            {!isMobile &&
                                <>
                                    {/* <div className='orcircle'>או</div> */}
                                    <div className='iconSignin iconSigninsms'>
                                        <img src={usersms} />
                                    </div>
                                </>
                            }
                            <div className="formContainer">
                                <fieldset>
                                    <legend>עם קוד חד פעמי ב- SMS</legend>
                                    <div className="formRow">
                                        <FormField
                                            type="text"
                                            name="MODsmsID"
                                            label="ת.זהות"
                                            id="Mod-smsMODID"
                                            Component={FormTextField}
                                            variant="standard"
                                            required
                                            validators={[onlyNumbers, length, require]}
                                            className={clsx("formField")}
                                            minLength={9}
                                            checkonlynumbers={'true'}
                                            inputProps={{ maxLength: 9 }}
                                        />
                                        <FormField
                                            type="text"
                                            name="PhoneNumber"
                                            label="טלפון נייד"
                                            id="Mod-PhoneNumber"
                                            Component={FormTextField}
                                            variant="outlined"
                                            required
                                            validators={[onlyNumbers, minLength, require]}
                                            className={clsx("formField", "formFieldPhone")}
                                            minLength={10}
                                            inputProps={{ maxLength: 10 }}
                                            checkonlynumbers={'true'}
                                        />

                                        {/* <div className='forgotpasswordlink'>
                                        
                                        </div> */}
                                    </div>
                                </fieldset>

                                <div className="submitWrap">
                                    <button ref={btnSMSRef} type="submit" name="submit" className="submitBut" >שלח קוד לנייד שלי</button>
                                    <div>
                                        {Dispatcher && Dispatcher.goToRegistration.length > 0 &&
                                            <a name="goToRegistration" className="registerLink " href={Dispatcher.goToRegistration}>{Dispatcher.RegistrationText}</a>
                                        }
                                        {/* {isMobile && Dispatcher && Dispatcher.InfoText.length > 0 &&
                                            <div className='moreinfocontainer' dangerouslySetInnerHTML={{ __html: Dispatcher.InfoText }}>

                                            </div>
                                        } */}
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
            }
        </FormContainer>
    }

    return (<>
        {/* <LoginHeader /> 
        <div className='loginwrapper'>
            <div className="ModLogin">
                <h1>כניסה לאיזור אישי</h1>
                <h2>אזור אישי ל{Dispatcher && Dispatcher.siteName}</h2>*/}
        {!isMobile ?
            <><div className='logincontainer'>
                {/* {formwithpassword()} */}
                {formwithsms()}
            </div>
                {/* {Dispatcher && Dispatcher.InfoText.length > 0 &&
                    <div className='moreinfocontainer' dangerouslySetInnerHTML={{ __html: Dispatcher.InfoText }}>

                    </div>
                } */}
                </>
            : <div className='mobileview'>
                <div className='mobileheader'>
                    <div className='headerbackground'>
                        {/* <div className={!isSMSview ? 'tabBtn  active' : 'tabBtn'} onClick={(e) => setIsSMSview(false)}>עם הסיסמה שלך</div> */}
                        <div className={isSMSview ? 'tabBtn  active' : 'tabBtn'} onClick={(e) => setIsSMSview(true)}>עם קוד חד פעמי בSMS</div>
                    </div>
                </div>
                {/* {!isSMSview && formwithpassword()} */}
                {isSMSview && formwithsms()}

            </div>
        }
 {Dispatcher && Dispatcher.InfoText.length > 0 &&
                    <div className='moreinfocontainer' dangerouslySetInnerHTML={{ __html: Dispatcher.InfoText }}>

                    </div>
                }
    </>
    )
});
export default Login;